import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../header/headerComp';
import HeadTitle from '../headTitle/headTitleComp';
import Menu from '../menu/menuComp';
import ResponsiveMenu from '../menu/responsiveMenuComp';
import Footer from '../../../footer/footerComp';

import '../../../../css/parentZoneMain.scss';
import '../../../../css/uploadsComp.scss';
import '../../../../css/responsive/parentZoneMain.scss';
import '../../../../css/responsive/uploadsComp.scss';
import { FETCH_API } from '../../../../services/api';
import { GET_UPLOADS } from '../../../../services/consts';
import { useIsMounted } from '../../../../services/useIsMounted';
import ReactLoading from 'react-loading';
import { SetHeaderMetaTags } from '../../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

const Uploads: React.FC = () => {
    const isMounted = useIsMounted();
    const [files, setFiles] = useState<any>(undefined);
    const [text, setText] = useState<any>(undefined);
    let navigate = useNavigate();

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Uploads' });
    }, []);

    useLayoutEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        let data: any = await FETCH_API(GET_UPLOADS, "GET");

        if (!isMounted()) return;
        if (!data.data) navigate('/signin');
        if (!data.data.files) setFiles([]);
        else setFiles(data.data.files);
        if (!data.data.text) setText([]);
        else setText(data.data.text);
    }

    return (
        <>

            <div className="centerScreen">
                <h2>Uploads</h2>
                <div className="info">
                    <p className="uploadsTitle">Check out the assignments your child has uploaded to MyZone.</p>
                    <div className="table-files">
                        <table className="tableDesktop">
                            <thead>
                                <tr>
                                    <th className="tablecol">Journey</th>
                                    <th className="tablecol">Assignment</th>
                                    <th className="tableFileCol">File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    files === undefined ?
                                        <tr>
                                            <td className="tablecell" colSpan={3}>
                                                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                                    <ReactLoading className="loading" type={'spin'} color={"#fff"} height={'50px'} width={'50px'} />
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        files.map((upload: any, i: number) => {
                                            let fileType = "";
                                            if (upload.file) fileType = upload.file.substr(upload.file.length - 4);
                                            return (
                                                <tr key={i}>
                                                    <td className="tablecell">{upload.jounrey}</td>
                                                    <td className="tablecell">{upload.step}</td>
                                                    <td className="tableFileCell" onClick={() => {
                                                        window.open(upload.file, '_blank');
                                                    }
                                                    }>{fileType}</td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-text">
                        <table className="tableDesktop table-text">
                            <thead>
                                <tr>
                                    <th className="tablecol">Journey</th>
                                    <th className="tablecol">Assignment</th>
                                    <th className="tableFileCol">Text</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    text === undefined ?
                                        <tr>
                                            <td className="tablecell" colSpan={3}>
                                                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                                    <ReactLoading className="loading" type={'spin'} color={"#fff"} height={'50px'} width={'50px'} />
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        text.map((upload: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="tablecell">{upload.jounrey}</td>
                                                    <td className="tablecell">{upload.step}</td>
                                                    <td className="tablecell">{upload.text}</td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        <table className="tableMobile ">
                            <tbody>
                                {files !== undefined &&
                                    files.map((upload: any, i: number) => {
                                        let fileType = "";
                                        if (upload.file) fileType = upload.file.substr(upload.file.length - 4);
                                        return (
                                            <React.Fragment key={i}>
                                                <tr >
                                                    <td className="tablecell">Journey name</td>
                                                    <td className="tablecell">{upload.jounrey}</td>
                                                </tr><tr >
                                                    <td className="tablecell">Assignment</td>
                                                    <td className="tablecell">{upload.step}</td>
                                                </tr><tr >
                                                    <td>file</td>
                                                    <td className="tableFileCell" onClick={() => {
                                                        window.open(upload.file, '_blank');
                                                    }}>{fileType}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {text !== undefined &&
                                    text.map((upload: any, i: number) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <tr>
                                                    <td className="tablecell">Journey name</td>
                                                    <td className="tablecell">{upload.jounrey}</td>
                                                </tr>
                                                <tr>
                                                    <td className="tablecell">Assignment</td>
                                                    <td className="tablecell">{upload.step}</td>
                                                </tr>
                                                <tr>
                                                    <td className="tablecell">Text</td>
                                                    <td className="tablecell">{upload.text}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }

                </div>
            </div>
        </>
    );
}

export default Uploads;
