import React from 'react';

import '../../../../css/headTitleComp.scss';



const HeadTitle: React.FC = () => {
  return (
    <div className="headTitle container-fluid">
        <div className="container">
            <h1>ParentZone</h1>
        </div>
    </div>
  );
}

export default HeadTitle;
