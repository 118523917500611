import { createSlice, PayloadAction } from '@reduxjs/toolkit';


type PathState = {
  utmUrlParams: string;
}

const initialState: PathState = {
    utmUrlParams: ''
}

const PathSlice = createSlice({
  name: 'path',
  initialState,
  reducers: {
    setPath(state, action: PayloadAction<string>) {
      state.utmUrlParams = action.payload;
    }
  }
});

export const {
    setPath
} = PathSlice.actions;

export default PathSlice.reducer;