// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FETCH_API } from '../../../services/api';
import { POST_AUTH } from '../../../services/consts';
import Header from '../../header/headerComp';
import HeadTitle from '../parentZone/headTitle/headTitleComp';
import Footer from '../../footer/footerComp';

import '../../../css/signinComp.scss';
import '../../../css/responsive/signinComp.scss';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

type FormData = {
    email: string;
    password: string;
};

const Signin: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [errorEmail, setErrorEmail] = useState<boolean>(true);
    const [errorPassword, setErrorPassword] = useState<boolean>(true);
    let navigate = useNavigate();

    useEffect(()=>{
        SetHeaderMetaTags({ title: 'Sign In' });
    },[]);
    
    useEffect(() => {
        document.getElementById("email")?.addEventListener("input", () => {
            validateEmail(document.getElementById("email").value);
        });
        document.getElementById("password")?.addEventListener("input", () => {
            validatePassword(document.getElementById("password").value);
        });
        window.addEventListener('popstate', function (event) {
            navigate("/");
        });
    }, [])

    useEffect(() => {

        if (displayError) {
            document.getElementById("error").style.display = "block";
        }
        else {
            document.getElementById("error").style.display = "none";
        }
    }, [displayError]);

    useEffect(() => {

        if (errorEmail || errorPassword) {
            document.getElementById("submitDiv")?.setAttribute("class", "inactiveSubmit");
        }
        else {
            document.getElementById("submitDiv")?.setAttribute("class", "activeSubmit");
        }
    }, [errorEmail, errorPassword]);

    const validateEmail = (_email: string) => {
        let regEmail = /[a-z0-9_-]+(\.[a-z0-9_-]+)*@([0-9a-z][0-9a-z-]*[0-9a-z]\.)+([a-z]{2,4})/i;
        if (_email.search(regEmail) == -1) {
            setErrorEmail(true);
            setDisplayError(true);
            return false;
        }
        setErrorEmail(false);
        setDisplayError(false);
        return true;

    }

    const validatePassword = (_password: any) => {
        if (_password.length < 8) {
            setErrorPassword(true);
            setDisplayError(true);
            return false;
        }
        setErrorPassword(false);
        setDisplayError(false);
        return true;
    }


    const onFormSub = async (dataBody: any) => {


        if (!validateEmail(dataBody.email)) return;
        if (!validatePassword(dataBody.password)) return;
        let email = dataBody.email;
        let password = dataBody.password;
        let data = await FETCH_API(POST_AUTH, "POST", { email, password });


        if (!data.isSuccessful) {
            document.getElementById("error").style.display = "block";
        }
        else {
            localStorage.setItem("firstname", data.data.firstName);
            localStorage.setItem("authToken", data.data.currentJwt);
            navigate('/parentzone/usernames-zone');
        }
    }


    return (
        <div className="parentZoneMain">
            <Header activeUser={false} />
            <main className="container-fluid">
                <HeadTitle />
                <div className="container signin">
                    <p className="title">Welcome to the ParentZone</p>
                    <form onSubmit={handleSubmit(onFormSub)}>
                        <p id="error" className="error">Incorrect email or password</p>

                        <div>
                            <input {...register('email')} id="email" type="email" name="email" placeholder="Email" maxLength={150} />
                        </div>
                        <div>
                            <input {...register('password')} id="password" type="password" name="password" placeholder="Password" maxLength={30} />
                        </div>
                        <div>
                            <Link to="/forgot-password">Forgot password?</Link>
                        </div>
                        <div id="submitDiv" className="inactiveSubmit">
                            <input id="formSubmit" type="submit" name="signin" value="Sign In" />
                        </div>
                    </form>
                </div>
            </main>
            <Footer activeUser={true} />
        </div>
    );
}

export default Signin;
function handleSubmit(onFormSub: (dataBody: any) => Promise<void>): React.FormEventHandler<HTMLFormElement> | undefined {
    throw new Error('Function not implemented.');
}

