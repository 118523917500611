// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SwitchButton from '../../../../common/switch/switch';

import '../../../../css/parentZoneMain.scss';
import '../../../../css/uploadsComp.scss';
import '../../../../css/settingsComp.scss';
import '../../../../css/responsive/parentZoneMain.scss';
import '../../../../css/responsive/uploadsComp.scss';
import '../../../../css/responsive/settingsComp.scss';
import { FETCH_API } from '../../../../services/api';
import { GET_PARENT_CHILD_INFO, GET_CHILD_INFO } from '../../../../services/consts';
import { globalParams } from '../../../../reducer/globalParamsComp';
import ReactLoading from 'react-loading';
import { SetHeaderMetaTags } from '../../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

const Settings: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [childID, setChildID] = useState<string>("");
  let navigate = useNavigate();

  useEffect(() => {
    SetHeaderMetaTags({ title: 'Settings' });
  }, []);

  useLayoutEffect(() => {
    getInfo();

  }, []);

  const getInfo = async () => {
    let data = await FETCH_API(GET_PARENT_CHILD_INFO, "GET");
    if (!data.data) navigate('/signin');


    setEmail(data.data.parentEmail);
    data = await FETCH_API(GET_CHILD_INFO, "GET");
    setChildID(data.data[0].id)
    if (globalParams.displaySettingsMess == true) window.scrollTo(0, 0);
    globalParams.displaySettingsMess = false;
  }

  const changeOtp = () => {
    navigate('/changeotp/' + email + "/" + childID);
  }

  return (
    <>
      <div className="centerScreen">
        <h2>Settings</h2>
        <div className="info">
          {globalParams.displaySettingsMess ?

            <p style={{ display: "inline-block", backgroundColor: "yellow", margin: "16px 0" }}>A new code generated successfully</p>

            : ""
          }
          <p className="uploadsTitle">Change screen time limitation: </p>
          <div className="switch">
            <SwitchButton
              leftText="No limit"
              rightText="Lock app for 1 day after each Journey"
            />
          </div>
          <div className='generate-wrapper'>
            {email && childID ?
              <button className="generateCode" onClick={changeOtp}>Generate a New Code</button>
              :
              <ReactLoading className="loading" type={'spin'} color={"#000"} height={'50px'} width={'50px'} />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
