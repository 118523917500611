import { createSlice, PayloadAction } from '@reduxjs/toolkit';


type AboutState = {
    isAboutOpen: boolean;
}

const initialState: AboutState = {
    isAboutOpen: false
}

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setAboutOpen(state, action: PayloadAction<boolean>) {
      state.isAboutOpen = action.payload;
    }
  }
});

export const {
    setAboutOpen
} = aboutSlice.actions;

export default aboutSlice.reducer;