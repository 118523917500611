import React from 'react';
import { Link } from 'react-router-dom';
import MenuItemComp from './menuItemComp';

import '../../../../css/parentZoneMenuComp.scss'

const Menu: React.FC = () => {
  return (
    <nav className="parentMenu">
        <MenuItemComp title="Info" bgcolor="#75c5db" path="/parentzone/usernames-zone"/>
        <MenuItemComp title="Uploads" bgcolor="#6ff6b0" path="/parentzone/uploads"/>
        <MenuItemComp title="Messages" bgcolor="#fde38b"  path="/parentzone/messages"/>
        <MenuItemComp title="Rewards" bgcolor="#ffbc38"  path="/parentzone/rewards"/>
        <MenuItemComp title="Settings" bgcolor="#ff8338"  path="/parentzone/settings"/>
    </nav>
  );
}

export default Menu;
