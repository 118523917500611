// @ts-nocheck
import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FETCH_API } from '../../../services/api';
import { POST_RESEND_MAIL } from '../../../services/consts';
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';

import '../../../css/verificationComp.scss';
import '../../../css/popup.scss';
import '../../../css/responsive/verificationComp.scss';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';
import { useAppSelector } from '../../../reducer/hook';


const Verification: React.FC = () => {
  const { email } = useParams();
  const { utmUrlParams } = useAppSelector(state => state.path);
  const location = useLocation();

  useEffect(() => {
    SetHeaderMetaTags({ title: 'Verification' });
    window.scrollTo(0, 0);
  }, [])

  const resendMail = () => {
    if (utmUrlParams) {
      let data = FETCH_API(POST_RESEND_MAIL + `${utmUrlParams}`, "POST", { email });
    }
    else {
      let data = FETCH_API(POST_RESEND_MAIL, "POST", { email });
    }
    let mess = [...document.querySelectorAll(".mess")];
    mess.map((item: any) => {
      item.style.display = "block";
    })
    document.getElementById("resendEmailLink").style.display = "none";
  }


  return (
    <div className="parentZoneMain">
      <Header activeUser={false} />
      <main className="container-fluid">
        <div className="d-flex screen container" style={{ justifyContent: "start", alignItems: "start" }}>
          <div className="verification">
            <p>Great, we're almost done.</p>
            <p>A verification link was sent to: <span>{email}</span></p>
            <p className="mt">Please click on the link to finish the registration process.</p>
            <p className="mt mess">A new verification email was sent to you.<br /></p>
            <p className="mess" style={{ color: "#000" }}>If you still did not receive an email:</p>
            <p className="mess" style={{ color: "#000" }}>1. Please check your spam folder and promotions tab.</p>
            <p className="mess" style={{ color: "#000" }}>2. Please make sure you entered the correct email address. If you didn’t, you can sign up again {''}
              {
                location.search.toLowerCase().includes('utm_source') ?
                  <Link to={`/signup/${utmUrlParams}`}>here</Link>
                  : <Link to='/signup'>here</Link>

              }
              .</p>
            <Link id="resendEmailLink" to={'/signup/verification/' + email + `/${utmUrlParams}`} onClick={() => { resendMail(); return false }}>Didn't get an email</Link>
          </div>
        </div>
      </main>
      <Footer activeUser={true} />
    </div>
  );
}

export default Verification;
