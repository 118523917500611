import React, { useEffect, useLayoutEffect, useState, Dispatch, SetStateAction } from 'react';
import * as ReactDOM from 'react-dom';

import './confirmPopup.scss';
import { Navigate, useNavigate } from 'react-router';

interface ConfirmPopupProps {
    setOpen: Dispatch<SetStateAction<boolean>>,
    setAnswer: Dispatch<SetStateAction<boolean>>,
    confirmMode: boolean
}

const ConfirmPopup = (props: ConfirmPopupProps) => {
    
    let navigate = useNavigate();

  return (
    <>
        <div className="confirm-popup">
            <div className='inner-popup'>
                {props.confirmMode ?
                    <p>Are you sure you entered the correct information?</p>
                    :
                    <p>The reward is on its way!</p>
                }
                {props.confirmMode ?
                    <div className='d-flex popup-btns'>
                        <button onClick={()=>{props.setAnswer(true)}}>YES</button>
                        <button onClick={()=>{props.setOpen(false)}}>Return to form</button>
                    </div>
                    :
                    <button className='close-btn' onClick={()=>{navigate("/")}}>Close</button>
                }
            </div>
        </div>
    </>
  );
}

export default ConfirmPopup;
