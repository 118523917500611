import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

interface MenuItem {
    title: string,
    bgcolor: string,
    path: string
}

const MenuItemComp: React.FC<MenuItem> = (props) => {

  return (
    <div className="parentMenuLink" style={{backgroundColor:props.bgcolor}}>
        
            <NavLink 
                className={(navData) => navData.isActive ? "whiteBG" : "" }
                to={props.path}
            >
            <p className="menuItemText" style={{
                backgroundColor: props.bgcolor
            }}>{props.title}</p>
                 
             </NavLink>
    </div>
  );
}

export default MenuItemComp;
