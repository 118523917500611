import { createSlice, PayloadAction } from '@reduxjs/toolkit';


type ParamsState = {
  isEnabledSignUp: boolean;
}

const initialState: ParamsState = {
  isEnabledSignUp: false
}

const paramsSlice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    setEnabledSignUp(state, action: PayloadAction<boolean>) {
      state.isEnabledSignUp = action.payload;
    }
  }
});

export const {
  setEnabledSignUp
} = paramsSlice.actions;

export default paramsSlice.reducer;