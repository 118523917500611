interface globalParams {
  userFirstName: string
  message?: string
  homePageInfo?: any
  footerText: any[]
  timer?: any
  timerTenSeconds: any
  displayPopupFromSignUp?: boolean
  displaySettingsMess?: boolean
  parentChildInfo?: any
}

export const globalParams: globalParams = {
    userFirstName: "",
    message: "",
    homePageInfo: false,
    footerText: [],
    timer: false,
    timerTenSeconds: false,
    displayPopupFromSignUp: false,
    displaySettingsMess: false,
    parentChildInfo: false
  }