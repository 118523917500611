import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../css/parentZoneMain.scss';
import '../../../../css/uploadsComp.scss';
import '../../../../css/rewardsComp.scss';
import '../../../../css/responsive/parentZoneMain.scss';
import '../../../../css/responsive/uploadsComp.scss';
import '../../../../css/responsive/rewardsComp.scss';
import { FETCH_API } from '../../../../services/api';
import { GET_PRIZES } from '../../../../services/consts';
import { useIsMounted } from '../../../../services/useIsMounted';
import ReactLoading from 'react-loading';
import { SetHeaderMetaTags } from '../../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

const Rewards: React.FC = () => {
    const isMounted = useIsMounted();
    const [prizes, setPrizes] = useState<any>(undefined);
    let navigate = useNavigate();

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Rewards' });
    }, []);

  useLayoutEffect(()=>{
    getInfo();
  },[]);

  const getInfo = async () => {
    let data = await FETCH_API(GET_PRIZES, "GET");
    if(!isMounted()) return;
    if(!data.data) navigate('/signin');
    setPrizes(data.data);
  }

  return (
    <>
              <div className="centerScreen">
                <h2>Rewards</h2>
                <div className="info">
                    <p className="uploadsTitle">These are the rewards your child chose on the MyZone app.</p>
                    <table className="tableDesktop">
                        <thead>
                            <tr>
                                <th className="tablecol">Reward</th>
                                <th className="tablecol">Points</th>
                                <th className="tablecol">Status</th>
                                <th className="tablecol">Date</th>
                                <th className="tablecol">Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                prizes === undefined ?
                                    <tr>
                                        <td className="tablecell" colSpan={5}>
                                            <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                                <ReactLoading  className="loading" type={'spin'} color={"#fff"} height={'50px'} width={'50px'} />
                                            </div>
                                        </td>
                                    </tr>
                                :
                                prizes.map((prize: any, i: number) => {
                                    let date;
                                    let fileType = "";
                                    if(prize.creationTime) date = new Date(prize.creationTime);
                                    if(prize.prize.image) fileType = prize.prize.image.substr(prize.prize.image.length - 4);
                                    return (
                                        
                                        <><tr key={i}>
                                            <td className="tablecell">{prize.prize.header}</td>
                                            <td className="tablecell">{prize.prize.points}</td>
                                            <td className="tablecell">{prize.prizeStatus.name}</td>
                                            <td className="tablecell">{
                                                date ?
                                                    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
                                                : ""
                                            }</td>
                                            <td className="tablecell" style={{cursor: "pointer"}} onClick={() => { window.open(prize.prize.image, '_blank'); } }>{fileType}</td>
                                        </tr></>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <table className="tableMobile">
                            { prizes !== undefined &&
                                prizes.map((prize: any, i: number) => {
                                    let date;
                                    let fileType = "";
                                    if(prize.prize.creationTime) date = new Date(prize.prize.creationTime);
                                    if(prize.prize.image) fileType = prize.prize.image.substr(prize.prize.image.length - 4);
                                    return (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td className="tablecell">Prize name</td>
                                                <td className="tablecell">{prize.prize.header}</td>
                                            </tr>
                                            <tr>
                                                <td className="tablecell">Points</td>
                                                <td className="tablecell">{prize.prize.points}</td>
                                            </tr>
                                            <tr>
                                                <td className="tablecell">Status</td>
                                                <td className="tablecell">{prize.prizeStatus.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="tablecell">Date</td>
                                                <td className="tablecell">{
                                                    date ?
                                                    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
                                                    : ""
                                                }</td>
                                            </tr>
                                            <tr>
                                                <td className="tablecell">Image</td>
                                                <td className="tablecell" style={{cursor: "pointer"}} onClick={() => { window.open(prize.prize.image, '_blank'); } }>{fileType}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                            
                    </table>
                </div>
              </div>
            
    </>
  );
}

export default Rewards;
