import { configureStore } from '@reduxjs/toolkit';
import paramsReducer from './paramsSlice';
import aboutSlice from './aboutSlice';
import lockSlice from './lockSlice';
import parentZoneSlice from './parentZoneSlice';
import pathSlice from './pathSlice';


const store = configureStore({
  reducer: {
    map: paramsReducer,
    about: aboutSlice,
    lock: lockSlice,
    parentZone: parentZoneSlice,
    path: pathSlice
  }
})

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch