// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FETCH_API } from '../../../services/api';
import { GET_PARENT_CHILD_INFO, POST_AUTH, POST_FORGOT_PASSWORD, POST_RENEW_PASSWORD } from '../../../services/consts';
import Header from '../../header/headerComp';
import HeadTitle from '../parentZone/headTitle/headTitleComp';
import Footer from '../../footer/footerComp';

import '../../../css/signinComp.scss';
import '../../../css/forgotPasswordComp.scss';
import '../../../css/responsive/signinComp.scss';
import '../../../css/responsive/forgotPasswordComp.scss';

interface FormValues {
    password: string,
    confirm: string,
    match: string
  };

const RenewPassword: React.FC = () => {
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<boolean>(true);
    const [errorConfirm, setErrorConfirm] = useState<boolean>(true);
    const [subClicked, setSubClicked] = useState<boolean>(false);
    const [activeSubmit, setActiveSubmit] = useState<boolean>(false);
    const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
    const [sendToken, setSendToken] = useState<string>();
    const { register, handleSubmit, getValues, setError, clearErrors, formState: { errors }  } = useForm<FormValues>();
    
  let navigate = useNavigate();
  let token: any;

  useLayoutEffect(()=>{
    let url = new URL(window.location.href).searchParams;
    token = url.get("token");
    if(!token) navigate('/');
    setSendToken(token)
  },[])



  const validateConfirm = (_confirm: string) =>{
      let password = getValues("password");
      
      if(password != _confirm) setError("match", {type: "custom"});
      else if(password.length >= 8 && _confirm == password){
        clearErrors();
        setActiveSubmit(true)
      }
  }


  const onFormSub = async (formData: FormValues) => {
      setSubClicked(true);
        let password = getValues("password");
        let confirm = getValues("confirm");
        if(!sendToken || successSubmit) return;
        let data= await FETCH_API(POST_RENEW_PASSWORD, "POST", {token: sendToken, password: password, passwordConfirm:confirm});
        
        if(!data.data){
            document.getElementById("error").style.display = "block";
             document.getElementById("error").innerHTML= "Unknown error, please try again";
        }
        else {
            setSuccessSubmit(true)
            document.getElementById("error").style.display = "none";
            document.getElementById("success").style.display = "inline-block";
            document.getElementById("submitDiv")?.setAttribute("class", "inactiveSubmit");
            document.getElementById("formSubmit")?.setAttribute("disabled", "");
        }
    }  
  

  return (
    <div className="parentZoneMain">
        <Header activeUser={false}/>
        <main className="container-fluid">
            <div className="container signin forgot">
                <p className="title">Choose a new password:</p>
                <form onSubmit={handleSubmit(onFormSub)} autoComplete="off">
                    <p id="error" className="error">Incorrect password</p>
                    <div id="success" className="success" style={{display: "none", textAlign:"center"}}>
                        <p style={{backgroundColor: "yellow"}}>Password changed successfully</p>
                        <p style={{marginTop: "4px", backgroundColor: "yellow"}}>Click <Link to="/signin"style={{color:"blue", margin: 0}}>here</Link> to sign in</p>
                    </div>
                    {
                        errors.password &&
                        <p id="errorPassword" className="error" style={{display: "inline"}}>Password must be at least 8 characters long</p>
                    }
                    {
                        !errors.password && errors.match &&
                        <p id="errorPassword" className="error" style={{display: "inline"}}>Passwords do not match</p>
                    }
                    <div>
                        <input {...register("password", { required: true, minLength: 8 })}  type="password" name="password" placeholder="Password"  maxLength={30} autoComplete="off" />
                    </div>
                    <div>
                        <input {...register("confirm", { required: true, minLength: 8 })}  type="password" name="confirm" placeholder="Confirm password" maxLength={30} autoComplete="off" onChange={(e)=>{validateConfirm(e.target.value)}}/>
                    </div>
                    <div id="submitDiv" className={!errors.password && !errors.confirm && !errors.match && (subClicked || activeSubmit) ? "activeSubmit" : "inactiveSubmit"}>
                        <input type="submit" name="change" value="Change" />
                    </div>
                </form>
            </div>
        </main>
        <Footer  activeUser={true} />
    </div>
  );
}

export default RenewPassword;

