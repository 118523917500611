import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";
import { FETCH_API } from '../../../services/api';
import { POST_CONFIRMATION, POST_RESEND_MAIL, POST_SEND_OTP, PUT_GET_OTP } from '../../../services/consts';
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';


import '../../../css/mailConfirmation.scss';
import '../../../css/responsive/mailConfirmation.scss';
import '../../../css/responsive/mess.scss';
import { getToPathname } from 'react-router/lib/router';
import { globalParams } from '../../../reducer/globalParamsComp';


const Mess: React.FC = (props) => {

    const [message, setMessage] = useState<string | null>();

    let navigate = useNavigate();
    

    useEffect(()=>{
        if(!globalParams.message) navigate('/');
        else {
            setMessage(globalParams.message);
        }
    },[])
 
  return (
    <div className="parentZoneMain">
        <Header activeUser={false}/>
        <main className="container-fluid" style={{minHeight: "100vh"}}>
            
            <div className="d-flex screen container message" style={{justifyContent: "start", alignItems:"start"}}>
                <div>
                    <h1 className="messPageTitle" style={{marginTop:"175px"}}>{message}</h1>
                    <p style={{fontSize:"22px"}}>Back to <Link to="/">HomePage</Link></p>
                </div>
            </div>
        </main>
        <Footer activeUser={true} />
    </div>
  );
}

export default Mess;


