import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import MenuItemComp from './menuItemComp';

import '../../../../css/responsive/parentZoneMenu.scss'

const ResponsiveMenu: React.FC = () => {
  return (
    <div className="parentMenuResponsive container-fluid">
        <nav className="container d-flex">
          <NavLink style={({ isActive }) =>
              isActive
                ? {
                    background: '#fff',
                  }
                : { background: '#72c4db' }
            } to="/parentzone/usernames-zone"><img src="/images/parentZone/menu_usernameszone_icon.png" alt="Usernames Zone" /></NavLink>
          <NavLink style={({ isActive }) =>
              isActive
                ? {
                    background: '#fff',
                  }
                : { background: '#6ff6b0' }
            }to="/parentzone/uploads"><img src="/images/parentZone/menu_uploads_icon.png" alt="Uploads" /></NavLink>
          <NavLink style={({ isActive }) =>
              isActive
                ? {
                    background: '#fff',
                  }
                : { background: '#fde38b' }
            }to="/parentzone/messages"><img src="/images/parentZone/menu_messages_icon.png" alt="Messages" /></NavLink>
          <NavLink style={({ isActive }) =>
              isActive
                ? {
                    background: '#fff',
                  }
                : { background: '#ffbc38' }
            }to="/parentzone/rewards"><img src="/images/parentZone/menu_rewards_icon.png" alt="Rewards" /></NavLink>
          <NavLink style={({ isActive }) =>
              isActive
                ? {
                    background: '#fff',
                  }
                : { background: '#ff8338' }
            }to="/parentzone/settings"><img src="/images/parentZone/menu_settings_icon.png" alt="Settings" /></NavLink>
        </nav>
    </div>
  );
}

export default ResponsiveMenu;
