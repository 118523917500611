// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FETCH_API } from '../../../../services/api';
import { GET_PARENT_CHILD_INFO } from '../../../../services/consts';
import Header from '../../../header/headerComp';
import HeadTitle from '../headTitle/headTitleComp';
import Menu from '../menu/menuComp';
import ResponsiveMenu from '../menu/responsiveMenuComp';
import Footer from '../../../footer/footerComp';

import '../../../../css/parentZoneMain.scss';
import '../../../../css/usernamesZoneComp.scss';
import '../../../../css/responsive/parentZoneMain.scss';
import '../../../../css/responsive/usernamesZoneComp.scss';
import { SetHeaderMetaTags } from '../../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

const UsernamesZone: React.FC = () => {

  const [info, setInfo] = useState<any>();
  const [activeJourneyDesc, setActiveJourneyDesc] = useState<any>("");
  const [remainingJourneys, setRemainingJourneys] = useState<string>("");
  const [completedJourneys, setCompletedJourneys] = useState<string>("");
  let navigate = useNavigate();

  useEffect(() => {
    SetHeaderMetaTags({ title: 'Info' });
  }, []);

  useEffect(() => {
    if (!info) getInfo();
  }, []);

  useEffect(() => {
    if (info?.remainigJourneys != null)
      setRemainingJourneys(journesArrayToString(info?.remainigJourneys));
    if (info?.completedJourneys != null)
      setCompletedJourneys(journesArrayToString(info?.completedJourneys));
  }, [info])

  const getInfo = async () => {
    let data = await FETCH_API(GET_PARENT_CHILD_INFO, "GET");
    if (!data.data) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("firstname");
      navigate('/signin');
    }
    if (data.data.activeJourney != null) {
      setActiveJourneyDesc(data.data.activeJourney);
    }
    setInfo(data.data);

  }

  const journesArrayToString = (_journeys: any = []) => {
    let str: string = "";
    _journeys.map((journey: any) => {
      if (journey.name != activeJourneyDesc.name) str += journey.name + ", ";
    });
    str = str.substring(0, str.length - 2);
    return str;
  }

  return (
    <>

      <div className="centerScreen">
        <h2>Info</h2>
        <div className="info">
          <div className="d-flex infoRow">
            <p className="infoTitle">Parent's Name:</p>
            <p className="infoText">{info?.parentName}</p>
          </div>
          <div className="d-flex infoRow">
            <p className="infoTitle">Parent's Email:</p>
            <p className="infoText">{info?.parentEmail}</p>
          </div>
          <div className="d-flex infoRow">
            <p className="infoTitle">Child's name:</p>
            <p className="infoText">{info?.childName}</p>
          </div>
          <div className="d-flex infoRow">
            <p className="infoTitle">Child's Year of Birth:</p>
            <p className="infoText">{info?.dateOfBirth}</p>
          </div>
          <div className="d-flex infoRow">
            <p className="infoTitle">Active Journey:</p>
            <p className="infoText">{activeJourneyDesc.name}</p>

          </div>
          <div className="d-flex infoRow">
            <p className="infoTitle">Completed Journeys:</p>
            <p className="infoText">{completedJourneys}</p>
          </div>
          <div className="d-flex infoRow">
            <p className="infoTitle">Remaining Journeys:</p>
            <p className="infoText">{remainingJourneys}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsernamesZone;
