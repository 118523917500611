import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../../../css/parentZoneMain.scss';
import '../../../../css/uploadsComp.scss';
import '../../../../css/messagesComp.scss';
import '../../../../css/responsive/parentZoneMain.scss';
import '../../../../css/responsive/uploadsComp.scss';
import '../../../../css/responsive/messagesComp.scss';
import { FETCH_API } from '../../../../services/api';
import { GET_MESSAGES } from '../../../../services/consts';
import { useIsMounted } from '../../../../services/useIsMounted';
import ReactLoading from 'react-loading';
import { SetHeaderMetaTags } from '../../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

const Messages: React.FC = () => {
    const isMounted = useIsMounted();
    const [messages, setMessages] = useState<any>(undefined);
    let navigate = useNavigate();

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Messages' });
    }, []);

    useLayoutEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        let data = await FETCH_API(GET_MESSAGES, "GET");
        if (!isMounted()) return;
        if (!data.data) navigate('/signin');
        setMessages(data.data);
    }

    return (
        <>
            <div className="centerScreen">
                <h2>Messages</h2>
                <div className="info">
                    <p className="uploadsTitle">Check out the communication your child has had with the MyZone team.</p>
                    <table className="tableDesktop">
                        <thead>
                            <tr>
                                <th className="tablecol">Date</th>
                                <th className="tablecol">Message</th>
                                <th className="tableFileCol">File</th>
                            </tr>
                        </thead>
                        <tbody>
                            {messages === undefined ?
                                <tr>
                                    <td className="tablecell" colSpan={3}>
                                        <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                            <ReactLoading className="loading" type={'spin'} color={"#fff"} height={'50px'} width={'50px'} />
                                        </div>
                                    </td>
                                </tr>
                                :
                                messages.map((mess: any, i: number) => {
                                    let fileType = "";
                                    if (mess.attachmentUrl) fileType = mess.attachmentUrl.substr(mess.attachmentUrl.length - 4);

                                    return (
                                        <tr key={i}>
                                            {<td className="tablecell">{
                                                mess.creationDate ?
                                                    new Date(mess.creationDate).getDate()
                                                    + "-" +
                                                    (new Date(mess.creationDate).getMonth() + 1)
                                                    + "-" +
                                                    new Date(mess.creationDate).getFullYear()
                                                    : ""
                                            }</td>}
                                            <td className="tablecell"><p className="messContent">{mess.content}</p></td>
                                            <td className="tableFileCell" onClick={() => { window.open(mess.attachmentUrl, '_blank'); }}>{fileType}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <table className="tableMobile">
                        <tbody>
                            {messages !== undefined &&
                                messages.map((mess: any, i: number) => {
                                    let fileType = "";
                                    if (mess.attachmentUrl) fileType = mess.attachmentUrl.substr(mess.attachmentUrl.length - 4);

                                    return (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td className="tablecell">Date</td>
                                                <td className="tablecell">{
                                                    mess.creationDate ?
                                                        new Date(mess.creationDate).getDate()
                                                        + "-" +
                                                        (new Date(mess.creationDate).getMonth() + 1)
                                                        + "-" +
                                                        new Date(mess.creationDate).getFullYear()
                                                        : ""}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tablecell">Message</td>
                                                <td className="tablecell messContent">{mess.content}</td>
                                            </tr>
                                            <tr>
                                                <td>File</td>
                                                <td className="tableFileCell" onClick={() => { window.open(mess.attachmentUrl, '_blank'); }}>{fileType}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Messages;
