// @ts-nocheck
import React, { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Parallax } from 'react-parallax';
import { GET_PAGES } from '../../services/consts';
import { FETCH_API, IApiResponseModel } from '../../services/api';
import Header from '../header/headerComp';
import Footer from '../footer/footerComp';

import '../../css/main.scss';
import '../../css/responsive/main.scss';
import '../../css/responsive/mainSizes.scss';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import FaqItem from './faqItemComp';
import { globalParams } from '../../reducer/globalParamsComp';
import { useIsMounted } from '../../services/useIsMounted';
import { uuid } from '../../common/uuid';
import { useAppDispatch, useAppSelector } from '../../reducer/hook';
import { setPath } from '../../reducer/pathSlice';


const Main: React.FC = () => {
  const isMounted = useIsMounted();
  const [pages, setPages] = useState<any>([]);
  const section1_bg = "/images/homepage/block1_bg2.png";
  const section1_bg_resp = "/images/homepage/block1_resp.png";
  const section6_bg = "/images/homepage/block6_bg.png";
  const section6_bg_resp = "/images/homepage/block6_bg_resp.png";

  const [block4Text, setBlock4Text] = useState<string[]>([]);
  const [block4DisplayedText, setBlock4DisplayedText] = useState<any>({});

  const [block3Carousel, setBlock3Carousel] = useState<any>([]);
  const [block3CarouselID, setBlock3CarouselID] = useState<number>(0);

  const [block5Carousel, setBlock5Carousel] = useState<any>([]);
  const [block5CarouselID, setBlock5CarouselID] = useState<number>(1);

  const [block7list, setBlock7list] = useState<string>("");
  const [block7body, setBlock7body] = useState<string>("");

  const [block8Carousel, setBlock8Carousel] = useState<any>([]);
  const [block8CarouselID, setBlock8CarouselID] = useState<number>(0);

  const [block9Carousel, setBlock9Carousel] = useState<any>([]);
  const [block9CarouselID, setBlock9CarouselID] = useState<number>(0);


  const [footerText, setFooterText] = useState<string[]>([]);


  const respDesktop = useMediaQuery({ query: '(min-width: 576px)' })
  const resp576 = useMediaQuery({ query: '(max-width: 576px)' })

  const location = useLocation();
  const { utmUrlParams } = useAppSelector(state => state.path);

  const dispatch = useAppDispatch();

  let block3len = 0;
  let block8len = 0;

  useEffect(() => {
    if (location.search.toLowerCase().includes('utm_source')) {
      dispatch(setPath(location.search + '&msbclid=' + uuid()));
    }
  }, []);

  useEffect(() => {
    getPages().then(data => {
      if (isMounted()) {
        let element = document.querySelector('.block7');
        window.addEventListener('scroll', function () {
          Visible(element);
        });
        window.scrollTo(0, 0);
      }
    })
  }, [])

  useEffect(() => {
    let img = document.getElementsByClassName("react-parallax-bgimage")[0];
    document.getElementsByClassName("react-parallax-bgimage")[0].remove();
    document.getElementsByClassName("block1_parallax")[0].append(img);
    document.getElementById("block1_fade").setAttribute("class", "title fade-in");
    document.getElementById("block1_fade2").setAttribute("class", "title fade-in");

  }, [])


  const getPages = async () => {

    let data;
    let home;
    if (localStorage["home"]) home = JSON.parse(localStorage["home"]);

    if (!home || Date.now() > parseInt(home.ttl)) {
      localStorage.removeItem("firstname");
      data = await FETCH_API(GET_PAGES, "GET");
      localStorage.setItem("home", JSON.stringify({ data: data.data, ttl: Date.now() + 86400 }));
      globalParams.homePageInfo = data;
      globalParams.footerText = [data.data[27].body, data.data[30].body, data.data[31].body, data.data[29].body];
    }
    else {
      data = home;
    }

    setPages(data.data);
    globalParams.footerText = [data.data[27].body, data.data[30].body, data.data[31].body, data.data[29].body];
    setFooterText([data.data[27].body, data.data[30].body, data.data[31].body, data.data[29].body]);

    let block9CarouselElem = data.data.filter((item) => {
      return item.id >= 11 && item.id <= 21;
    })
    let block4Text = data.data.filter((item) => {
      return item.id >= 33 && item.id <= 37;
    })



    setBlock9Carousel(block9CarouselElem);

    setBlock8Carousel([...data.data[7].contentGalleries]);
    block8len = data.data[7].contentGalleries.length;
    let block7body = (data.data[6].body.split("\n")).filter((item: string, i: number) => {
      return item != "";
    });

    let block7table: string = "";

    for (let i = 1; i < block7body.length; i += 3) {
      block7table += "<div className='row d-flex'>";
      for (let j = i; j < (i + 3) && j < block7body.length; j++) {
        block7table += "<div className='cell d-flex'>";
        block7table += `<div className='marker'></div><p>${block7body[j]}</p>`
        block7table += "</div>";
      }
      block7table += "</div>";
    }

    setBlock7list(block7table);
    setBlock7body(block7body[0]);

    setBlock4Text(block4Text);
    let array = [...data.data[2].contentGalleries];

    setBlock3Carousel(array);
    block3len = data.data[2].contentGalleries.length;
    startTimer();
  }

  const startTimer = (_id: number = 0,) => {
    let id = _id;
    let block3img = block3len;
    let block8img = block8CarouselID;
    if (globalParams.timer != false) clearInterval(globalParams.timer);
    globalParams.timer = setInterval(() => {
      if (!isMounted()) {
        clearInterval(globalParams.timer);
        return;
      }
      id++;

      setTimeout(() => {
        if (block3img == 0) {


          let block3 = document.querySelector(`main .block3 .slider-wrpr .slider .card[data-id='${block3len - 1}']`);
          if (block3) block3.setAttribute("class", "card fade-inIMG");

          let images = [...document.querySelectorAll(`main .block3 .slider-wrpr .slider .card`)];

          setTimeout(() => {
            images.map((item) => {
              item.setAttribute("class", "card");
            })
          }, 500)
        }

        let block3tmp = document.querySelector(`main .block3 .slider-wrpr .slider .card[data-id='${block3img}']`);
        if (block3tmp) block3tmp.setAttribute("class", "card fade-outIMG");


        let block8 = document.querySelector("main .block8 .img .screen");
        if (block8) block8.setAttribute("class", "screen screen-fade-out");
      }, 3000)


      if (block8img < block8len - 1) block8img++;
      else block8img = 0;
      setBlock8CarouselID(block8img);


      block3img--;

      if (block3img < 0) {
        block3img = block3len - 1;
      }

      setBlock3CarouselID(block3img);

    }, 3500);
  }

  const block5CarouselAction = (_side: number) => {
    let imgNum = block5CarouselID;
    if (_side == 1) imgNum++;
    else imgNum--;
    if (imgNum > 3) imgNum = 1;
    if (imgNum < 1) imgNum = 3;
    setBlock5CarouselID(imgNum);
  }

  const block9CarouselAction = (_id: number) => {
    let id = _id;
    if (globalParams.timerTenSeconds) clearInterval(globalParams.timerTenSeconds);
    globalParams.timerTenSeconds = setInterval(() => {
      if (!isMounted()) {
        clearInterval(globalParams.timerTenSeconds);
        return;
      }
      let block9 = document.getElementById("block9text");
      if (block9) block9.setAttribute("class", "text fade-out");

      document.querySelectorAll("main .block9 .btn").forEach((item) => {
        item.setAttribute("class", "btn");
      });

      id++;
      setTimeout(() => {
        if (id > 10) {
          id = 0;
          setBlock9CarouselID(0);
        }
        else {
          setBlock9CarouselID(id);
        }
        let block9 = document.getElementById("block9text");
        if (block9) block9.setAttribute("class", "text");
      }, 500)
    }, 10000);
    setBlock9CarouselID(id);
  }



  const Visible = (target: any) => {
    let targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom
    },
      windowPosition = {
        top: window.pageYOffset,
        bottom: window.pageYOffset + document.documentElement.clientHeight
      };

    if (targetPosition.bottom > windowPosition.top &&
      targetPosition.top < windowPosition.bottom) {
      document.querySelector(".block7")?.setAttribute("class", "block7 block7visible");
    };
  };

  const spliceToLines = (_str: string) => {
    if (!_str) return "";
    let p = document.createElement("p");
    let br = document.createElement("br");
    let linesArr = _str.split("\n");
    let text = "";
    linesArr.map((item: string, i: number) => {
      p.append(item);
      if (linesArr[i + 1]) p.append(br);
    })
    return p;
  }

  return (
    <div>
      <Header activeUser={false} ifMain={true} />
      <main className="main">
        <Parallax className="block1_parallax" bgImage={section1_bg} strength={500}>
          <section className="block1">
            <div className="container">
              <h2 className="subTitle">{pages[0]?.subTitle}</h2>
              <h1 id="block1_fade" className="title fade-out">{pages[0]?.title}</h1>
              <p className="text">
                {pages[0]?.body}
              </p>
            </div>
          </section>
        </Parallax>
        <section className="block1 block1_responsive">
          <div className="container">
            <h3 className="subTitle">{pages[0]?.subTitle}</h3>
            <h2 id="block1_fade2" className="title fade-out">{pages[0]?.title}</h2>
            <p className="text">{pages[0]?.body}</p>
          </div>
          <img src={section1_bg_resp} alt="" />
        </section>
        <section className="block2">
          <div className="container d-flex">
            <img className='certified-badge' src='images/homepage/CertifiedBadge.png' alt='Certified Badge' />
            <div style={{ width: "100%" }}>
              <p className="textBody" >{pages[1]?.body}</p>
            </div>
            <div className="signup">
              {
                location.search.toLowerCase().includes('utm_source') ?
                  <Link to={`/signup/${utmUrlParams}`}>Sign Up for Free</Link>
                  : <Link to='/signup'>Sign Up for Free</Link>

              }
              <p className="sText">No credit card required</p>
            </div>
          </div>
        </section>
        <section className="block3" >
          <div className="container">

            <div>
              <h3 className="subTitle">{pages[2]?.subTitle}</h3>
              <h2 className="title">{pages[2]?.title}</h2>
              <p className="text">{pages[2]?.body}</p>
            </div>
            <div className="slider-wrpr">
              <div className="mask"><img className="phoneIMG" src="images/homepage/block3_phone.png" alt="" /></div>
              <div className="slider">
                {
                  block3Carousel.map((item: any, i: number) => {
                    return (
                      <div key={i} className="card" data-id={i}><img src={item.imagePath} alt="" /></div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </section>
        <section className="block4">
          <div className="container">
            <h3 className="subTitle">{pages[3]?.subTitle}</h3>
            <h2 className="title">{pages[3]?.title}</h2>
            <div className="leftRight d-flex" style={{ alignItems: "flex-start" }}>
              <div className="left">
                <p className="text">{pages[3]?.body}</p>
              </div>
              <div className="right">
                <figure style={block4DisplayedText.body ? { display: "none" } : { display: "block" }}>
                  <img src="/images/homepage/block4_pencil.svg" alt="" />
                  <figcaption>Click to Discover</figcaption>
                </figure>
                <div style={block4DisplayedText.body ? { display: "block" } : { display: "none" }} className="desc">
                  <h4>{block4DisplayedText.title}</h4>
                  <p>{block4DisplayedText.body}</p>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div className="journeys-img"></div>
            <div className="journeysResp x-scroll x-mandatory">
              <div className="journeys wrapper d-flex">
                <div className={block4DisplayedText.id == 33 ? "journey journey1 active" : "journey journey1"} onClick={() => { setBlock4DisplayedText(block4Text[0]); }}></div>
                <div className={block4DisplayedText.id == 34 ? "journey journey2 active" : "journey journey2"} onClick={() => { setBlock4DisplayedText(block4Text[1]) }}></div>
                <div className={block4DisplayedText.id == 35 ? "journey journey3 active" : "journey journey3"} onClick={() => { setBlock4DisplayedText(block4Text[2]) }}></div>
                <div className={block4DisplayedText.id == 36 ? "journey journey4 active" : "journey journey4"} onClick={() => { setBlock4DisplayedText(block4Text[3]) }}></div>
                <div className={block4DisplayedText.id == 37 ? "journey journey5 active" : "journey journey5"} onClick={() => { setBlock4DisplayedText(block4Text[4]) }}></div>
              </div>
            </div>
            <div className="journeys d-flex x-scroll x-mandatory wrapper">
              <div className={block4DisplayedText.id == 33 ? "journey journey1 active" : "journey journey1"} onClick={() => { setBlock4DisplayedText(block4Text[0]); }}></div>
              <div className={block4DisplayedText.id == 34 ? "journey journey2 active" : "journey journey2"} onClick={() => { setBlock4DisplayedText(block4Text[1]) }}></div>
              <div className={block4DisplayedText.id == 35 ? "journey journey3 active" : "journey journey3"} onClick={() => { setBlock4DisplayedText(block4Text[2]) }}></div>
              <div className={block4DisplayedText.id == 36 ? "journey journey4 active" : "journey journey4"} onClick={() => { setBlock4DisplayedText(block4Text[3]) }}></div>
              <div className={block4DisplayedText.id == 37 ? "journey journey5 active" : "journey journey5"} onClick={() => { setBlock4DisplayedText(block4Text[4]) }}></div>
            </div>
            <div className="descResp">
              <h4>{block4DisplayedText.title}</h4>
              <p className="text">{block4DisplayedText.body}</p>
            </div>
          </div>
        </section>
        <section className="block5 container-fluid">
          <div className="container">
            <h2 className="title">{pages[4]?.title}</h2>
            <h3 className="subTitle">{pages[4]?.subTitle}</h3>
            <div className="leftRight d-flex">
              <p className="text">{pages[4]?.body}</p>
              <div className="slider d-flex">
                <div className="arrowLeft">
                  <img src="/images/homepage/arrowLeft.png" alt="" onClick={() => { block5CarouselAction(0) }} />
                </div>
                <div className="carouselImg">
                  <img className="pencil" src="/images/homepage/block5pencil.png" alt="" />
                  <img className="bubbles" src={`/images/homepage/block5carousel${block5CarouselID}.png`} alt="" />
                  <img className={`mainIMG mainIMG${block5CarouselID}`} src={`/images/homepage/mainIMG${block5CarouselID}.png`} alt="" />
                </div>
                <div className="arrowRight">
                  <img src="/images/homepage/arrowRight.png" alt="" onClick={() => { block5CarouselAction(1) }} />
                </div>
              </div>
              <div className="responsiveArrows d-flex">
                <div className="arrowLeft">
                  <img src="/images/homepage/responsiveArrowLeft.png" alt="" onClick={() => { block5CarouselAction(0) }} />
                </div>
                <div className="arrowRight">
                  <img src="/images/homepage/responsiveArrowRight.png" alt="" onClick={() => { block5CarouselAction(1) }} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="block6">
          <Parallax className="block6_parallax d-flex" bgImage={section6_bg} strength={500}>
            <section className="block6">
              <div className="container">
                <div >
                  <h2 className="title">{pages[5]?.title}</h2>
                  <p className="text">{pages[5]?.body}</p>
                </div>
              </div>
            </section>
          </Parallax>
        </section>
        <section className="block6 block6_responsive">
          <div className="container">
            <h2 className="title">{pages[5]?.title}</h2>
            <p className="text">
              {pages[5]?.body}
            </p>
          </div>
          <img src={section6_bg_resp} alt="" />
        </section>
        <section className="block7">
          <div className="container">
            <h2 className="title">{pages[6]?.title}</h2>
            <h3 className="subTitle">{pages[6]?.subTitle}</h3>
            <p className="text">{block7body}</p>
            <div className="list">
              {parse(block7list)}

            </div>
          </div>
        </section>
        <section className="block8 container-fluid">
          <div className="container">
            <div>
              <div className="text">
                <h2 className="title">{pages[7]?.title}</h2>
                <h3><span>{pages[7]?.subTitle}</span></h3>
                <p className="desc">{pages[7]?.body}</p>
              </div>
              <div className="img">
                <img className="comp" src="images/homepage/block9_comp.png" alt="" />
                {
                  block8Carousel.map((item: any, i: number) => {
                    return (
                      <img key={i} className={block8CarouselID == i ? "screen screen-fade-out" : "screen"} data-id={i} src={item.imagePath} alt="" />
                    )
                  })
                }
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </section>
        <section className="block9 container-fluid">
          <div className="container">
            <h3 className="subTitle">{pages[8]?.subTitle}</h3>
            <h2 className="title">{pages[8]?.title}</h2>
          </div>
          <div className="containerCarousel container">
            <div className="d-flex carouselRow">
              <div className="d-flex carouselContent">
                {block9Carousel.length > 0 ?
                  <p id="block9text" className="text">{block9Carousel[block9CarouselID].body}</p>
                  : ""
                }
                <div className="carouselAuthor">
                  {block9Carousel.length > 0 ?
                    <p><span>{block9Carousel[block9CarouselID].title.substr(0, 4)}</span>{block9Carousel[block9CarouselID].title.substr(4)}</p>
                    : ""
                  }
                </div>
              </div>
            </div>
            <div className="btnsRow">
              <div className="btns d-flex">

                {
                  pages.map((item: any, i: number) => {
                    return (
                      (item.id >= 11 && item.id <= 21) ?
                        <div key={i} data-id={item.id - 11} className={block9CarouselID == item.id - 11 ? "btn active" : "btn"} onClick={() => { block9CarouselAction(item.id - 11); }} >

                        </div>
                        : ""
                    )

                  })
                }
              </div>
            </div>
          </div>
        </section>

        <section className="block10">
          <div className="container d-flex">
            <h2 className="title">FAQ</h2>
            <div className="faqList">
              {
                pages.map((item: any, i: number) => {
                  return (
                    (item.id >= 22 && item.id <= 27) ?
                      <FaqItem key={i} title={item.title} body={item.body} />
                      : ""
                  )
                })
              }

            </div>
          </div>
        </section>
      </main>
      {globalParams.footerText.length > 0 ?
        <Footer showAboutUs={true} activeUser={false} footerSignUpBtn={true} textModal={globalParams.footerText} />
        : ""
      }
    </div>
  );
}

export default Main;
