import React, { useLayoutEffect, useState } from 'react';

import '../../css/faqItemComp.scss';


import { useEffect } from 'react';

interface FaqItem{
  title: string,
  body: string
}


const FaqItem: React.FC<FaqItem> = (props) => {

  const [ifOpen, setIfOpen] = useState<boolean>(false);

  return (
    <div className={ifOpen ? "faqItem faqItemOpen" : "faqItem faqItemClose" } onClick={()=>{setIfOpen(ifOpen == false)}}>
      <div className="faqTitle d-flex">
        <h4>{props.title}</h4>
        {ifOpen ? 
          <img src='/images/homepage/faqOpen.png' alt=''/>
          :
          <img src='/images/homepage/faqClose.png' alt=''/>
        }
        
      </div>
      <div className="faqText">
        <p>{props.body}</p>
      </div>
    </div>
  );
}

export default FaqItem;
