// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { globalParams } from '../../reducer/globalParamsComp'

import '../../css/headerComp.scss';
import '../../css/responsive/headerComp.scss';
import { FETCH_API } from '../../services/api';
import { GET_PARENT_CHILD_INFO, GET_PARENT_USERS } from '../../services/consts';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '../../reducer/hook';
import { setAboutOpen } from '../../reducer/aboutSlice';

interface ifActiveUser {
  activeUser: boolean
  ifMain?: boolean
}

const Header: React.FC<ifActiveUser> = (props) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 820 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 821 })
  const [firstName, setFirstName] = useState<string>("");
  const [aboutUsModal, setAboutUsModal] = useState<boolean>(false);
  const [aboutUsText, setAboutUsText] = useState<string>("");
  const [logoClassname, setLogoClassName] = useState<string>("logo");
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { utmUrlParams } = useAppSelector(state => state.path);
  const location = useLocation();

  useLayoutEffect(() => {
    setFirstName(localStorage["firstname"] ? localStorage["firstname"] : "");
  }, [globalParams.userFirstName])

  useEffect(() => {
    setAboutUsText(globalParams.footerText[0]);
  }, [globalParams.footerText])

  useEffect(() => {
    if (localStorage["firstname"] && props.ifMain) {
      setLogoClassName("logo active-user-mainpage");
    }
    if (!localStorage["firstname"] && !props.ifMain) {
      setLogoClassName("logo nouser");
    }
  }, [])

  const getUserName = async () => {
    if (!globalParams.parentChildInfo) {
      let data = await FETCH_API(GET_PARENT_CHILD_INFO, "GET");
      if (data.data) {
        localStorage.setItem("firstname", data.data.parentName);
        globalParams.parentChildInfo = data.data;
      }
    }
    setFirstName(localStorage["firstname"] ? localStorage["firstname"] : "");
  }

  return (
    <header style={props.activeUser ? { paddingTop: "17px", paddingBottom: "17px" } : { paddingTop: "24px", height: "82px" }}>
      <div id="header" className="container-fluid">
        <div className="container">
          <div className="d-flex">
            <div className={logoClassname}>
              <Link to="/">
                <img src='/images/main/logo.png' alt="MyZone" />
              </Link>

            </div>

            {!props.activeUser ?
              <nav className="menu">
                {props.ifMain ?
                  <>
                    {isDesktopOrLaptop && <a className="about-us" href="#footer" onClick={() => {
                      dispatch(setAboutOpen(true));
                    }}>AboutUs </a>}
                    {isTabletOrMobile && <p style={{ display: "inline" }} onClick={() => {
                      setAboutUsModal(aboutUsModal == false);
                    }}>AboutUs </p>}

                  </>
                  : ""
                }
                {props.ifMain ? <>
                  <Link to="/parentzone">ParentZone </Link>
                  {!localStorage["firstname"] ?
                    <div className="signup-btn-wrapper">
                      {
                        location.search.toLowerCase().includes('utm_source') ?
                          <Link className="signUpBtn" to={`/signup/${utmUrlParams}`}>Sign Up for Free</Link>
                          : <Link className="signUpBtn" to='/signup'>Sign Up for Free</Link>

                      }
                      <p>No credit card required</p>
                    </div>
                    :
                    <Link className="userName" to="/parentzone">Hello, {localStorage["firstname"]}</Link>
                  }
                </>
                  : ""
                }
              </nav>
              :
              <div className="menu signout d-flex">
                <p className='userName'>Hello, {firstName}</p>
                <Link className="signOutBtn" to="/" onClick={() => { localStorage.removeItem("authToken"); localStorage.removeItem("firstname"); globalParams.userFirstName = "" }}>Sign out</Link>
              </div>
            }
          </div>
        </div>
      </div>
      {!props.activeUser && props.ifMain ?
        <>
          <div className={!localStorage["firstname"] ? "container-fluid signUpBtnMobile" : "container-fluid signUpBtnMobile active-user"} >
            <div className="container">
              {!localStorage["firstname"] ?
                <div className="signup-btn-wrapper">
                  <Link to="/signup">Sign Up for Free</Link>
                  <p>No credit card required</p>
                </div>
                : ""
              }
            </div>
          </div>
        </>
        :
        <div className="respB" style={props.ifMain ? { height: "6px", backgroundColor: "#72c4db" } : { height: "0" }}></div>
      }
      {props.ifMain && aboutUsModal ?
        <div className="aboutUsModal">
          <div className='closeAboutUs d-flex'>
            <button onClick={() => { setAboutUsModal(false) }}>
              <img src='/images/signUp/closeIcon.png' alt='Close' />
            </button>
          </div>
          <p>{aboutUsText}</p>
        </div>
        : ""
      }
    </header>
  );
}

export default Header;

