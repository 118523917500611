// @ts-nocheck
import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";
import { FETCH_API } from '../../../services/api';
import { GET_PAGE_BY_ID, GET_REGISTRATION_MODE, POST_REGISTER } from '../../../services/consts';
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';

import '../../../css/signUpComp.scss';
import '../../../css/responsive/signupComp.scss';
import '../../../css/popup.scss';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from '../../../reducer/hook';

type FormData = {
    name: string,
    email: string,
    password: string,
    childName: string,
    yearBirth: string,
    accept: boolean | undefined
};

const SignUp: React.FC = () => {
    const isMobileSize = useMediaQuery({ maxWidth: 414 })
    const { register, handleSubmit, getValues, setError, clearErrors, formState: { errors, isValid, isDirty } } = useForm<FormData>();

    const [isEnabledSignUp, setIsEnabledSignUp] = useState<boolean>(false);
    const [signUpClosedMess, setSignUpClosedMess] = useState<any>("");

    const [popup1, setPopup1] = useState<boolean>(false);

    const [popup2, setPopup2] = useState<boolean>(false);
    const [selectOption, setSelectOption] = useState<boolean>(false);
    const [lock, setLock] = useState<boolean>(false);
    const [acceptAge, setAcceptAge] = useState<boolean>(false);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [ifClickedCheckbox, setIfClickedCheckBox] = useState<boolean>(false);

    const [termsPopup, setTermsPopup] = useState<boolean>(false);
    const [isGreenBtn, setIsGreenBtn] = useState<boolean>(false);
    const [signUpErrorText, setSignUpError] = useState<string>("");
    let navigate = useNavigate();
    const { utmUrlParams } = useAppSelector(state => state.path);

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Sign Up' });
        dataLayer.push({ 'event': 'registration-start' });
        window.scrollTo(0, 0);
        checkEnabled();

    }, []);

    useEffect(() => {
        if ((!acceptAge || !acceptTerms) && ifClickedCheckbox) setError("accept", { type: "custom" });
        else clearErrors('accept');
    }, [acceptAge, acceptTerms, ifClickedCheckbox])

    useEffect(() => {
        if (isDirty && Object.keys(errors).length === 0 && acceptAge && acceptTerms) setIsGreenBtn(true);
        else setIsGreenBtn(false);
    }, [isDirty, Object.keys(errors), acceptAge, acceptTerms])



    const checkEnabled = async () => {
        let ifEnabled = await FETCH_API(GET_REGISTRATION_MODE, "GET");
        if (ifEnabled.data == true) {
            setIsEnabledSignUp(true);
        }
        else {
            let message: any = await FETCH_API(GET_PAGE_BY_ID + "/38", "GET");
            let messageArr = message.data.body.split("\n");
            setSignUpClosedMess(messageArr);
        }
    }

    const popup1Toggle = () => {
        if (!popup1) {

            document.getElementById("popup1").style.display = "block";
            setPopup1(true);
        }
        else {

            document.getElementById("popup1").style.display = "none";
            setPopup1(false);
        }
    }

    const popup2Toggle = () => {
        if (!popup2) {

            document.getElementById("popup2").style.display = "block";
            setPopup2(true);
        }
        else {

            document.getElementById("popup2").style.display = "none";
            setPopup2(false);
        }
    }

    const selectOptionToggle = () => {
        if (!selectOption) {

            document.getElementById("selectOption").style.display = "block";

            document.getElementById("selectInput").style.borderRadius = "20px 0 0 0";

            document.getElementById("listIcon").src = "/images/signUp/selectOpen.png";
            setSelectOption(true);
        }
        else {

            document.getElementById("selectOption").style.display = "none";

            document.getElementById("selectInput").style.borderRadius = "20px 0 0 20px";

            document.getElementById("listIcon").src = "/images/signUp/selectClose.png";
            setSelectOption(false);
        }
    }

    const lockToggle = () => {
        if (!lock) {

            document.querySelector("#selectInput p").innerHTML = "App will lockdown for 1 day after each Journey";

            document.querySelector("#selectOption p").innerHTML = "No limit";
            setLock(true);
        }
        else {

            document.querySelector("#selectInput p").innerHTML = "No limit";

            document.querySelector("#selectOption p").innerHTML = "App will lockdown for 1 day after each Journey";
            setLock(false);
        }
        selectOptionToggle();
    }


    const validateName = () => {
        let name = getValues("name");
        if (!name || name.length < 2) setError("name", { type: "custom" });
        else {
            clearErrors('name');
        }
    }

    const validateEmail = () => {
        let email = getValues("email");
        let regEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email || email.search(regEmail) == -1) {
            setError("email", { type: "custom" });
        }
        else {
            clearErrors('email');
        }
    }

    const validatePassword = () => {
        let password = getValues("password");
        if (!password || password.length < 8) setError("password", { type: "custom" });
        else {
            clearErrors('password');
        }
    }

    const validateChildName = () => {
        let childName = getValues("childName");
        if (!childName || childName.length < 2) setError("childName", { type: "custom" });
        else {
            clearErrors('childName');
        }
    }
    const location = useLocation();

    const validateYearBirth = () => {
        let enteredYear = getValues("yearBirth");
        let regyear = "/(?:(?:19|20)[0-9]{2})/";
        let curYear = new Date().getFullYear();

        if (!parseInt(enteredYear) || parseInt(enteredYear) > curYear || parseInt(enteredYear) < curYear - 100) {
            setError("yearBirth", { type: "custom" });
            return false;
        }
        else {
            clearErrors('yearBirth');
        }
        return true;
    }

    const onFormSub = async (dataBody: any) => {
        validateEmail();
        let validYear = validateYearBirth();
        if (!isDirty || Object.keys(errors).length !== 0 || !acceptAge || !acceptTerms || !validYear) return;


        let body = {
            isLockTimeLimit: lock,
            firstName: dataBody.name,
            email: dataBody.email,
            password: dataBody.password,
            children: [{
                childFirstName: dataBody.childName,
                childBirthYear: dataBody.yearBirth
            }]

        }
        if (utmUrlParams) {            
            let data = await FETCH_API(POST_REGISTER + `${utmUrlParams}`, "POST", body);
            if (data.data == true) {
                navigate('/signup/verification/' + dataBody.email + `/${utmUrlParams}`);
            }
            else {
                setSignUpError(data.displayMessage);
                setIsGreenBtn(false);
            }
        }
        else {
            let data = await FETCH_API(POST_REGISTER+`${utmUrlParams}`, "POST", body);
            if (data.data == true) {
                navigate('/signup/verification/' + dataBody.email);
            }
            else {
                setSignUpError(data.displayMessage);
                setIsGreenBtn(false);
            }
        }
    }



    return (
        <div className='parentZoneMain'>
            <Header activeUser={false} />
            <div className='pre-banner'>Pre-release version</div>
            <main className="container-fluid">
                <div className="d-flex screen container" style={{ justifyContent: "start", alignItems: "start" }}>
                    <div className="signup">
                        {isEnabledSignUp == true &&
                            <>
                                <div className="text">
                                    <p>Thank you for choosing MyZone! Please provide us with a few basic details.</p>
                                </div>
                                <div className="text d-flex">
                                    <img src='/images/signUp/marker_icon.png' alt='Marker' />
                                    <p>This information will help keep MyZone safe and transparent.</p>
                                </div>
                                <div className="text d-flex">
                                    <img src='/images/signUp/marker_icon.png' alt='Marker' />
                                    <p>We will never share this information with outside parties.</p>
                                </div>
                                <form onSubmit={handleSubmit(onFormSub)} name='signUp' onChange={() => { setSignUpError("") }}>
                                    <p className="formTitle">First, your information:</p>
                                    <div className="d-flex" style={{ alignItems: "flex-start" }}>
                                        <div className="formInputDiv">
                                            <label htmlFor="nameInput">Name:<span>*</span></label>
                                            <br />

                                            <input id="nameInput" {...register('name', { required: true })} type="text" name="name" placeholder="Adult’s/Teacher’s name" maxLength={50} onBlur={validateName} />
                                            <p id="errorName" className="error" style={errors.name ? { display: "block" } : { display: "none" }}>* Name must be at least 2 characters</p>
                                        </div>
                                        <div className="formInputDiv">
                                            <label htmlFor="emailInput">Email:<span>*</span></label>
                                            <br />

                                            <input id="emailInput" {...register('email', { required: true })} type="email" name="email" placeholder="Adult's Email" maxLength={150} onBlur={validateEmail} />
                                            <p id="errorEmail" className="error" style={errors.email ? { display: "block" } : { display: "none" }}>* Email address is incorrect</p>
                                        </div>
                                        <div className="formInputDiv">
                                            <div style={{ position: "absolute", width: "0" }}>
                                                <div id="popup1" className="popup popup1 d-flex">
                                                    <img className="popupClose" src='/images/signUp/closeIcon.png' alt='' onClick={popup1Toggle} />
                                                    <p className="popupText">Signing into your account allows you to monitor and track your child’s progress and experience within the app. Password must be at least 8 characters.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex password" style={{ justifyContent: "flex-start" }}>
                                                <label htmlFor="passwordInput">Password:<span>*</span></label>
                                                <img src="/images/signUp/qIcon.png" alt="" onClick={popup1Toggle} />
                                            </div>

                                            <input id="passwordInput"{...register('password', { required: true })} type="password" name="password" placeholder="Password" maxLength={30} onBlur={validatePassword} />
                                            <img className="d-m" src="/images/signUp/qIcon.png" alt="" onClick={popup1Toggle} />
                                            <p id="errorPassword" className="error" style={errors.password ? { display: "block" } : { display: "none" }}>* Password must be at least 8 characters long</p>
                                        </div>
                                    </div>
                                    <p className="formTitle">Now, your child's info:</p>
                                    <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: "flex-start" }} >
                                        <div className="formInputDiv">
                                            <label htmlFor="childNameInput">First name:<span>*</span></label>
                                            <br />

                                            <input id="childNameInput" {...register('childName', { required: true })} type="text" name="childName" placeholder="First name" maxLength={50} onBlur={validateChildName} />
                                            <p id="errorChildName" className="error" style={errors.childName ? { display: "block" } : { display: "none" }}>* Name must be at least 2 characters</p>
                                        </div>
                                        <div className="formInputDiv" style={{ marginLeft: "42px" }}>
                                            <label htmlFor="childYearBirthInput">Year of birth<span>*</span> (YYYY):</label>
                                            <br />

                                            <input id="childYearBirthInput" {...register('yearBirth', { required: true })} type="text" name="yearBirth" placeholder={`${isMobileSize ? 'Year of birth (YYYY)' : 'Year of birth'}`} maxLength={4} onBlur={validateYearBirth} />

                                            <p id="errorYearBirth" className="error" style={errors.yearBirth ? { display: "block" } : { display: "none" }}>* This app is recommended for children 8-12 years old</p>
                                        </div>
                                    </div>
                                    <p className="formTitle" style={{ marginBottom: "5px" }}>MyZone allows you to limit screen time.</p>
                                    <div className="d-flex" style={{ justifyContent: "flex-start" }}>
                                        <p className="formTitle" style={{ marginTop: "0" }}>Please select your preference:</p>
                                        <div style={{ position: "absolute", width: "0" }}>
                                            <div id="popup2" className="popup popup2 d-flex">
                                                <img className="popupClose" src='/images/signUp/closeIcon.png' alt='' onClick={popup2Toggle} />
                                                <p className="popupText">This setting can be changed at any time in the ParentZone.</p>
                                            </div>
                                        </div>
                                        <img className="qIcon2" src="/images/signUp/qIcon.png" alt="" onClick={popup2Toggle} />
                                    </div>
                                    <div className="selectList">
                                        <div className="d-flex">
                                            <div className="d-flex" style={{ justifyContent: "flex-start", maxWidth: "546px" }} onClick={selectOptionToggle}>
                                                <div id="selectInput" className="selectInput">
                                                    <p>No Limit</p>
                                                </div>
                                                <img id="listIcon" src='/images/signUp/selectClose.png' alt='Open' />
                                            </div>
                                            <img className="d-m2" src="/images/signUp/qIcon.png" alt="" onClick={popup2Toggle} />
                                        </div>
                                        <div id="selectOption" className="selectOption" onClick={lockToggle}>
                                            <p>App will lockdown until the next day after each Journey</p>
                                        </div>
                                    </div>

                                    <div className="formConfirm d-flex">
                                        <div className="checkbox">
                                            <p id="errorAccept" className="error" style={errors.accept ? { display: "block" } : { display: "none" }} >* To register, you must agree to our terms&amp;conditions and that you are at least 18 years old</p>
                                            <div className="d-flex">
                                                <div id="acceptAge" className="checkboxBtn" onClick={() => { setAcceptAge(acceptAge == false); setIfClickedCheckBox(true) }}>
                                                    {acceptAge && <div className="checkboxBtnActive"></div>}
                                                </div>
                                                <p onClick={() => { setAcceptAge(acceptAge == false); setIfClickedCheckBox(true) }}>I'm 18 or older</p>
                                            </div>
                                            <div className="d-flex">
                                                <div id="acceptTerms" className="checkboxBtn" onClick={() => { setAcceptTerms(acceptTerms == false); setIfClickedCheckBox(true) }} >
                                                    {acceptTerms && <div className="checkboxBtnActive"></div>}
                                                </div>
                                                <p>I agree to MyZone's <Link to="/legal/myzone-terms-of-use.pdf" target="_blank">Terms of Use</Link></p>
                                            </div>
                                        </div>
                                        <p id="sendError" style={signUpErrorText.length ? { marginRight: "16px", display: "block" } : { marginRight: "16px", display: "none" }} className="error">{signUpErrorText}</p>
                                        <div className={isGreenBtn && !signUpErrorText ? "activeSubmit" : "inactiveSubmit"}>
                                            <input id="formSubmit" type="submit" name="signup" value="SignUp" />
                                        </div>
                                    </div>
                                </form>
                            </>
                        }
                        {isEnabledSignUp == false && signUpClosedMess != "" &&
                            <div className="signup-closed-message">
                                <div className="text">
                                    {
                                        signUpClosedMess.map((line: string) => {
                                            if (line == "")
                                                return (
                                                    <br />
                                                )
                                            else
                                                return (
                                                    <p>{line}</p>
                                                )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </main>
            <Footer displayPopupFromSignUp={termsPopup} displayPopup={setTermsPopup} activeUser={true} />
        </div>
    );
}

export default SignUp;
