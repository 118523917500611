const base_url = 'https://api.tomyzone.com/api'; //prod

export const createUrl = (url: string): string => `${base_url}/${url}`;

export const MY_ZONE_TOKEN = "authToken";
export const GET_PARENT_USERS = createUrl('users/admin/parent-users');
export const GET_PARENT_CHILD_INFO = createUrl('users/parent/get-child-info');
export const GET_MESSAGES = createUrl('messages/parent/child-messages');
export const GET_UPLOADS = createUrl('activity/parent/uploads-child');
export const GET_PRIZES = createUrl('prizes/parent/child-prizes');
export const GET_LOCK = createUrl('activity/parent/locked');
export const GET_CHILD_INFO = createUrl('users/parent/children');
export const GET_PAGES = createUrl('content/pages');
export const GET_PRIZE = createUrl('prizes/parent/child-prize');
export const GET_REGISTRATION_MODE = createUrl('metadata/admin/registration-mode');
export const GET_PAGE_BY_ID = createUrl('content/page');
export const PUT_LOCK = createUrl('activity/parent/update-locked');
export const PUT_GET_OTP = createUrl('auth/otp/parent/update');
export const POST_SEND_PRIZE = createUrl('prizes/parent/send-prize-info');
export const POST_REGISTER = createUrl('auth/parent/register');
export const POST_RESEND_MAIL = createUrl('auth/parent/confirmation/renew');
export const POST_CONFIRMATION = createUrl('auth/parent/confirmation');
export const POST_SEND_OTP = createUrl('otp/parent/send-otp');
export const POST_AUTH = createUrl('auth/parent/login');
export const POST_FORGOT_PASSWORD = createUrl('auth/parent/password/forgot');
export const POST_RENEW_PASSWORD = createUrl('auth/parent/password/reset');
