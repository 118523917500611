// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FETCH_API } from '../../../services/api';
import { POST_FORGOT_PASSWORD } from '../../../services/consts';
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';

import '../../../css/signinComp.scss';
import '../../../css/forgotPasswordComp.scss';
import '../../../css/responsive/signinComp.scss';
import '../../../css/responsive/forgotPasswordComp.scss';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';



const ForgotPassword: React.FC = () => {

    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [errorEmail, setErrorEmail] = useState<boolean>(true);
    const [subClicked, setSubClicked] = useState<boolean>(false);

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Forgot Password' });
    }, []);

    useEffect(() => {
        document.getElementById("email")?.addEventListener("input", () => {
            validateEmail(document.getElementById("email").value);
        });
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (displayError && subClicked) {
            document.getElementById("error").style.display = "block";
            document.getElementById("error").innerHTML = "Incorrect email";
        }
        else {
            document.getElementById("error").style.display = "none";
        }
    }, [displayError, subClicked]);

    useEffect(() => {

        if (errorEmail) {
            document.getElementById("submitDiv")?.setAttribute("class", "inactiveSubmit");
        }
        else {
            document.getElementById("submitDiv")?.setAttribute("class", "activeSubmit");
        }
    }, [errorEmail]);

    const validateEmail = (_email: string) => {
        let regEmail = /[a-z0-9_-]+(\.[a-z0-9_-]+)*@([0-9a-z][0-9a-z-]*[0-9a-z]\.)+([a-z]{2,4})/i;
        if (_email.search(regEmail) == -1) {
            setErrorEmail(true);
            setDisplayError(true);
            return false;
        }
        setErrorEmail(false);
        setDisplayError(false);
        return true;

    }

    const onFormSub = async (dataBody: any) => {
        setSubClicked(true);
        document.getElementById("success").style.display = "none";
        if (document.getElementById("submitDiv").getAttribute("class") == "inactiveSubmit") return;
        let email = document.getElementById("email").value;
        if (!validateEmail(email)) {
            setErrorEmail(true);
        }
        else {
            if (document.getElementById("submitDiv").getAttribute("class") == "inactiveSubmit") return;
            let data = await FETCH_API(POST_FORGOT_PASSWORD, "POST", { email });
            if (data.isSuccessful == false) {
                document.getElementById("error").style.display = "block";
                document.getElementById("error").style.display = "Email does not exist.";
            }
            else {
                document.getElementById("error").style.display = "none";
                document.getElementById("success").style.display = "inline-block";
            }
        }
        document.getElementById("submitDiv")?.setAttribute("class", "inactiveSubmit");
    }


    return (
        <div className="parentZoneMain" >
            <Header activeUser={false} />
            <main className="container-fluid">
                <div className="container signin forgot">
                    <p className="title">Enter your email to receive a password reset link</p>
                    <form onSubmit={handleSubmit(onFormSub)}>
                        <p id="error" className="error">The email you entered does not belong to any account</p>
                        <p id="success" className="success" style={{ backgroundColor: "yellow" }}>The link was sent to your email</p>
                        <div>
                            <input id="email" type="email" name="email" placeholder="Email" maxLength={150} />
                        </div>
                        <div>
                            <Link to="/signin">Back to sign in page</Link>
                        </div>
                        <div id="submitDiv" className="activeSubmit">
                            <input id="formSubmit" type="submit" name="send" value="Send" />
                        </div>
                    </form>
                </div>
            </main>
            <Footer activeUser={true} />
        </div>
    );
}

export default ForgotPassword;
function handleSubmit(onFormSub: (dataBody: any) => Promise<void>): React.FormEventHandler<HTMLFormElement> | undefined {
    throw new Error('Function not implemented.');
}

