import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../reducer/hook';
import { setLock } from '../../reducer/lockSlice';
import { FETCH_API } from '../../services/api';
import { GET_LOCK, PUT_LOCK } from '../../services/consts';
import ReactLoading from 'react-loading';
import './switch.scss';

interface SwitchText {
  leftText?: string,
  rightText?: string
}

const SwitchButton: React.FC<SwitchText> = (props) => {

  const { isLocked } = useAppSelector(state => state.lock);
  const dispatch = useAppDispatch();

  useEffect(()=> {
    if(isLocked === undefined) getLockApp();
  },[]);

  const getLockApp  = async () => {
    let data = await FETCH_API(GET_LOCK, "GET");
    let ifLockedApp: any = data.data ;
    dispatch(setLock(ifLockedApp));
  }

  const toggleEvent = async (lock: boolean) => {
    if(isLocked === undefined) return;
    sendApi(lock);
  }

  const sendApi = async (_lock: boolean) => {
    let body = {"lockJourney": _lock};
    let data = await FETCH_API(PUT_LOCK, "PUT", body);
  }

  return (
    
      <label className="switch2 switch2-checked d-flex" onMouseDown={()=>{
        toggleEvent(!isLocked);
        dispatch(setLock(!isLocked));
      }}>
      {isLocked === undefined ? 
        <>
          <ReactLoading className="loading" type={'bars'} color={"#fff"} height={'34px'} width={'34px'} />
        </>
        : ""
      }
      {
        isLocked === false ?
        <>
          <input type="checkbox" />
            <div className="d-flex">
              <p>{props.leftText}</p>
            </div>
            <p style={{marginTop:"-11px"}}>{props.rightText}</p>
         </>
         : ""
      }
      {
        isLocked === true ?
        <>
        <input type="checkbox" />
       
         <p style={{marginTop:"-11px", marginLeft: "11px"}}>{props.leftText}</p>
         <div className="d-flex">
           <p>{props.rightText}</p>
         </div>
         </>
        :""
      }
    </label>
  );
}

export default SwitchButton;
