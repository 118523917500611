// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";

import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { FETCH_API } from '../../../services/api';
import { POST_CONFIRMATION, POST_SEND_OTP, PUT_GET_OTP } from '../../../services/consts';
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';

import '../../../css/mailConfirmation.scss';
import '../../../css/responsive/mailConfirmation.scss';
import { globalParams } from '../../../reducer/globalParamsComp';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';
import { useAppSelector } from '../../../reducer/hook';


const MailConfirmation: React.FC = (props) => {

    const [ifClickedSubmit, setIfClickedSubmit] = useState<boolean>(false);
    const [ifInput, setIfInput] = useState<boolean>(false);
    const [ifMess, setIfMess] = useState<boolean>(false);
    const [ifEmail, setIfEmail] = useState<boolean>(false);
    const [ifNoNeeded, setIfNoNeeded] = useState<boolean>(false);

    const [phoneValue, setPhoneValue] = useState<'E164Number | undefined'>();

    const [errorOtp, setErrorOtp] = useState<boolean>();
    const [errorPhone, setErrorPhone] = useState<boolean>();
    const [errorEmail, setErrorEmail] = useState<boolean>();
    const [paramEmail, setParamEmail] = useState<string>("");
    const [paramToken, setParamToken] = useState<string>("");
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [otp, setOtp] = useState<string>("");
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
    const { emailFromSettings } = useParams();
    const { childID } = useParams();
    let navigate = useNavigate();
    let email: string | null;
    let token: string | null;
    const { utmUrlParams } = useAppSelector(state => state.path);
    const location = useLocation();

    useEffect(() => {
        getOtp();

        SetHeaderMetaTags({ title: 'Mail Confirmation' });
        window.scrollTo(0, 0)
    }, [])



    useEffect(() => {
        if (!ifMess && !ifNoNeeded) {

            document.getElementById("mess").innerHTML = "";

            document.getElementById("errorMess").style.display = "none";
            setErrorPhone(false);

            document.querySelector(".confirmation form .checkbox .react-tel-input .form-control")?.setAttribute("disabled", "");
        }
        else if (!ifMess && ifNoNeeded) {
            setIfNoNeeded(true);

            document.querySelector(".confirmation form .checkbox .react-tel-input .form-control")?.setAttribute("disabled", "");

        }
        else {

            document.querySelector(".confirmation form .checkbox .react-tel-input .form-control")?.removeAttribute("disabled");
            let elem = document.createElement("div");
            elem.setAttribute("class", "checkboxBtnActive");

            document.getElementById("mess").prepend(elem);

            document.querySelector(".confirmation form .checkbox .react-tel-input .form-control")?.addEventListener("keydown", validatePhoneInput);

            validatePhoneInput();
        }

    }, [ifMess])


    useEffect(() => {

        if (!ifEmail && !ifNoNeeded) {

            document.getElementById("email").innerHTML = "";

            document.getElementById("errorEmail").style.display = "none";

            document.getElementById("addressEmail")?.setAttribute("disabled", "");
            setErrorEmail(false);
        }
        else if (!ifEmail && ifNoNeeded) {
            setIfNoNeeded(true);

            document.getElementById("addressEmail")?.setAttribute("disabled", "");
        }
        else {

            document.getElementById("addressEmail")?.removeAttribute("disabled");
            let elem = document.createElement("div");
            elem.setAttribute("class", "checkboxBtnActive");

            document.getElementById("email").prepend(elem);

            document.getElementById("addressEmail").addEventListener("input", validateEmailInput);
            validateEmailInput();
        }
    }, [ifEmail])


    useEffect(() => {

        if (!ifNoNeeded) {

            document.getElementById("noNeeded").innerHTML = "";

        }
        else {

            document.getElementById("errorCheckbox").style.display = "none";

            document.getElementById("addressEmail").value = "";


            document.querySelector(".confirmation form .checkbox .react-tel-input .form-control").value = "";
            let elem = document.createElement("div");
            elem.setAttribute("class", "checkboxBtnActive");

            document.getElementById("noNeeded").prepend(elem);


            document.getElementById("email").innerHTML = "";

            document.getElementById("mess").innerHTML = "";


            document.getElementById("submitDiv").className = "activeSubmit";
            setIfMess(false);
            setIfEmail(false);
            setErrorPhone(false);
            setErrorEmail(false);

        }

    }, [ifNoNeeded])


    useEffect(() => {

        if (ifMess && errorPhone) {


            document.getElementById("submitDiv").setAttribute("class", "inactiveSubmit");
        }
        else if (ifEmail && errorEmail) {


            document.getElementById("submitDiv").setAttribute("class", "inactiveSubmit");
        }
        else if (!ifEmail && !ifMess && !ifNoNeeded) {

            document.getElementById("submitDiv").setAttribute("class", "inactiveSubmit");
        }
        else {

            document.getElementById("errorMess").style.display = "none";

            document.getElementById("errorEmail").style.display = "none";

            document.getElementById("submitDiv").setAttribute("class", "activeSubmit");
        }
        setFirstRender(true);
    }, [ifNoNeeded, ifMess, errorPhone, ifEmail, errorEmail, errorOtp])

    const validateOtp = (_ifBlur = false) => {

        let code = document.getElementById("codeInput").value;
        let reg = "[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))";
        let errorInput = null;

        if (!code.match(reg) || code.length != 6) {
            if (ifClickedSubmit == true || _ifBlur == true)
                errorInput = document.getElementById("errorInput");
            if (errorInput) errorInput.style.display = "block";
            setErrorOtp(true);
        }
        else {

            errorInput = document.getElementById("errorInput");
            if (errorInput) errorInput.style.display = "none";
            setErrorOtp(false);
            setOtp(code);
        }
    }

    const validatePhoneInput = () => {
        if (!ifMess) return;

        let phoneNum = document.querySelector(".confirmation form .checkbox .react-tel-input .form-control").value;

        if (!phoneNum || phoneNum.length < 15) {
            if (ifClickedSubmit == true)

                document.getElementById("errorMess").style.display = "block";
            setErrorPhone(true);
        }
        else {

            document.getElementById("errorMess").style.display = "none";
            setErrorPhone(false);

        }
    }

    const validateEmailInput = () => {
        if (!ifEmail) return;


        let email = document.getElementById("addressEmail").value;
        let regEmail = /[a-z0-9_-]+(\.[a-z0-9_-]+)*@([0-9a-z][0-9a-z-]*[0-9a-z]\.)+([a-z]{2,4})/i;
        if (email.search(regEmail) == -1) {
            if (ifClickedSubmit == true)

                document.getElementById("errorEmail").style.display = "block";
            setErrorEmail(true);
        }
        else {

            document.getElementById("errorEmail").style.display = "none";
            setErrorEmail(false);

        }
    }

    const getQueryVariable = (variable: string) => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    const getOtp = async () => {
        let data;
        email = getQueryVariable("email");
        token = getQueryVariable("token");

        localStorage.setItem("parentEmail", email);

        localStorage.setItem("parentToken", token);


        setParamEmail(email);

        setParamToken(token);


        if (!email && !token && !emailFromSettings) navigate('/');

        if (email && token) {
            data = await FETCH_API(POST_CONFIRMATION, "POST", { token, email });
            if (data.isSuccessful == false) {
                globalParams.message = data.displayMessage;

                navigate('/message');
            }

            setOtp(data.data);
        }
        if (emailFromSettings) {

            data = await FETCH_API(PUT_GET_OTP + "/" + childID, "PUT");

            setOtp(data.data);
        }

    }


    const changeCode = (e: any) => {
        e.preventDefault();


        if (!ifInput) {

            document.getElementById("codeInput").removeAttribute("disabled");

            document.getElementById("codeInput").focus();

            setIfInput(true);
        }
        else {

            document.getElementById("codeInput").setAttribute("disabled", "true");
            setIfInput(false);
            validateOtp();
        }
    }
    const onFormSub = async (dataBody: any) => {
        if (ifMess) validatePhoneInput();
        if (ifEmail) validateEmailInput();

        if (ifMess && errorPhone) return;
        if (ifEmail && errorEmail) return;
        if (!ifEmail && !ifMess && !ifNoNeeded) return;

        let data;

        if (emailFromSettings) {
            let body = {};
            let parentEmail;
            let email;
            let mess;
            let summary = false;
            if (paramEmail) {
                parentEmail = { parentEmail: paramEmail };
                summary = true;
            }
            if (emailFromSettings) {
                parentEmail = { parentEmail: emailFromSettings };
            }
            if (ifEmail) {

                email = { sendEmail: document.getElementById("addressEmail").value };
                body = { ...body, ...email };
            }
            if (ifMess) {

                mess = { sendPhone: document.querySelector(".confirmation form .checkbox .react-tel-input .form-control").value };
                body = { ...body, ...mess };
            }
            body = { ...body, ...parentEmail, otp, isSentMailSummary: summary };

            data = await FETCH_API(POST_SEND_OTP, "POST", body);
            globalParams.displaySettingsMess = true;
            navigate('/parentzone/settings');
        }
        else {
            let body = {};
            let parentEmail = { parentEmail: localStorage.getItem("parentEmail") };
            let email;
            let mess;
            let summary = true;
            if (ifEmail) {

                email = { sendEmail: document.getElementById("addressEmail").value };
                body = { ...body, ...email };
            }
            if (ifMess) {

                mess = { sendPhone: document.querySelector(".confirmation form .checkbox .react-tel-input .form-control").value };
                body = { ...body, ...mess };
            }
            body = { ...body, ...parentEmail, otp, isSentMailSummary: summary };
            data = await FETCH_API(POST_SEND_OTP, "POST", body);
            if (!!!utmUrlParams && location.search.toLowerCase().includes('utm_source')) {
                navigate(`/successReg/${location.search}`);
            }
            else {
                navigate(`/successReg/${utmUrlParams}`);
            }
        }
    }
    return (
        <div className="parentZoneMain">
            <Header activeUser={false} />
            <main className="container-fluid">
                <div className="d-flex screen container" style={{ justifyContent: "start", alignItems: "start" }}>
                    <div className="confirmation changeotp">
                        <p>For your convenience, the activation code and app link have been sent to your email.</p>
                        <p className='otp-text'>Your child's activation code for MyZone is:</p>
                        {
                            (ifInput || errorOtp) &&
                            <p id="errorInput" className="mess code" >
                                <span>
                                    * A code must be 6 characters long and contain at least one letter and one number
                                </span>
                            </p>

                        }

                        <form onSubmit={handleSubmit(onFormSub)}>
                            <input id="codeInput" type="text" name="code" defaultValue={otp} disabled maxLength={6} />
                            <br />

                            <p className="title">How would you like us to send the activation code and App link to your child?</p>
                            <p id="errorCheckbox" className="error">* select one of the options</p>
                            <div className="checkbox">

                                <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: "30px" }}>
                                    <div id="mess" className="checkboxBtn" onClick={() => { setIfNoNeeded(false); setIfMess(ifMess == false) }}>

                                    </div>
                                    <div>
                                        <p><span>Text Message</span> - please enter your child's mobile number (it will not be saved in our system).</p>
                                        {ifMess ?
                                            <PhoneInput
                                                country="us"
                                                placeholder="Enter phone number"
                                                onChange={() => { }} />
                                            :
                                            <PhoneInput
                                                disabled
                                                country="us"
                                                placeholder="Enter phone number"
                                                onChange={() => { }} />
                                        }
                                        <p id="errorMess" className="error">* invalid phone number</p>
                                    </div>
                                </div>
                                <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: "40px" }}>
                                    <div id="email" className="checkboxBtn" onClick={() => { setIfNoNeeded(false); setIfEmail(ifEmail == false) }}>

                                    </div>
                                    <div>
                                        <p><span>Email</span> - please enter your child's email address (it will not be saved in our system).</p>
                                        <input id="addressEmail" type="text" name="email" maxLength={150} />
                                        <p id="errorEmail" className="error">* invalid email</p>
                                    </div>
                                </div>
                                <div className="d-flex" style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: "40px" }}>
                                    <div id="noNeeded" className="checkboxBtn" onClick={() => { setIfNoNeeded(ifNoNeeded == false) }}>

                                    </div>
                                    <div>
                                        <p>No need to send, I'll pass the code along to them myself.</p>
                                    </div>
                                </div>
                                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                                    <div id="submitDiv" className="inactiveSubmit">
                                        <input id="formSubmit" type="submit" name="next" value="Next" onClick={() => { setIfClickedSubmit(true) }} />
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <Footer activeUser={true} />
        </div>
    );
}

export default MailConfirmation;


