import * as API_ROUTES from './consts';
import  { MY_ZONE_TOKEN } from './consts';

export interface IApiResponseModel<T> {
  isSuccessful: boolean;
  statusCode: number;
  innerCode: number;
  displayMessage: string;
  exception: string;
  data: T;
}

export const FETCH_API = async <T>(_url: string, _method: "POST" | "PUT" | "PATCH" | "DELETE" | "GET", _body?: any, isToken = true, isUpload = false): Promise<IApiResponseModel<T>> => {
    const headers = new Headers();
    if (isUpload) {
      headers.append('Content-Type', 'undefined')
    } else {
      headers.append('content-type', 'application/json');
    }
    if (isToken) headers.append('Authorization', `Bearer ${localStorage[MY_ZONE_TOKEN]}`);
      const resp = await fetch(_url, {
        method: _method,
        body: isUpload ? _body : JSON.stringify(_body),
        headers
      });
      return await resp.json();
  }

  