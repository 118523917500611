import React from 'react';
import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import  Main  from './comp/main/mainComp';
import  SignUp  from './comp/pages/signup/signUpComp';
import  Verification  from './comp/pages/signup/verificationComp';
import  MailConfirmation  from './comp/pages/signup/mailConfirmationComp';
import  SuccessReg  from './comp/pages/signup/successRegComp';
import  UsernamesZone  from './comp/pages/parentZone/usernamesZone/usernamesZoneComp';
import  Uploads  from './comp/pages/parentZone/uploads/uploadsComp';
import  Messages  from './comp/pages/parentZone/messages/messagesComp';
import  Rewards  from './comp/pages/parentZone/rewards/rewardsComp';
import  Settings  from './comp/pages/parentZone/settings/settingsComp';
import Signin from './comp/pages/signin/signinComp';
import ForgotPassword from './comp/pages/forgotpassword/forgotPasswordComp';
import RenewPassword from './comp/pages/forgotpassword/renewPassword';
import Mess from './comp/pages/mess/messComp';
import ParentZone from './comp/pages/parentZone/parentZone/parentZoneComp';
import SendPrize from './comp/pages/send-prize/sendPrizeComp';
import store from './reducer/store';
import { Provider } from 'react-redux';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route index={false} path="/message" element={<Mess />} ></Route>
            <Route index={false} path="/signup" element={<SignUp />}></Route>
            <Route index={false} path="/signup/verification/:email" element={<Verification />}></Route>
            <Route index={false} path="/forgot-password/" element={<ForgotPassword />}></Route>
            <Route index={false} path="/reset-password/" element={<RenewPassword />}></Route>
            <Route index={false} path="/mail-confirmation" element={<MailConfirmation />}></Route>
            <Route index={false} path="/changeotp/:emailFromSettings/:childID" element={<MailConfirmation />}></Route>
            <Route index={false} path="/successReg" element={<SuccessReg />}></Route>
            <Route index={false} path="/signin" element={<Signin />}></Route>
            <Route  path="/parentzone" element={<ParentZone />}>
              <Route path="/parentzone/usernames-zone" element={<UsernamesZone />}></Route>
              <Route  path="/parentzone/uploads" element={<Uploads />}></Route>
              <Route  path="/parentzone/messages" element={<Messages />}></Route>
              <Route  path="/parentzone/rewards" element={<Rewards />}></Route>
              <Route  path="/parentzone/settings" element={<Settings />}></Route>
            </Route>
            <Route index={false} path="/send-prize/:id" element={<SendPrize />}></Route>
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
function composeWithDevTools(): any {
  throw new Error('Function not implemented.');
}

function createStore(myZone: any, arg1: any) {
  throw new Error('Function not implemented.');
}

