import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { SetHeaderMetaTags } from '../../../../common/SetHeaderMetaTags/SetHeaderMetaTags';
import { globalParams } from '../../../../reducer/globalParamsComp';
import Footer from '../../../footer/footerComp';
import Header from '../../../header/headerComp';
import HeadTitle from '../headTitle/headTitleComp';
import Menu from '../menu/menuComp';
import ResponsiveMenu from '../menu/responsiveMenuComp';

const ParentZone: React.FC = () => {
  const location = useLocation();
  const [info, setInfo] = useState<any>();
  const [mainStyle, setMainStyle] = useState<string>("parentZoneMain");
  let navigate = useNavigate();

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Parent Zone' });
    }, []);

  useEffect(()=>{
    getInfo();
  },[]);

  useEffect(()=>{
    getMainStyle();
  },[location.pathname])

  const getInfo = async () => {
    if(!globalParams.userFirstName && !localStorage["authToken"]) navigate('/signin');
    else if(location.pathname == "/parentzone") navigate('/parentzone/usernames-zone');
  }

  const getMainStyle = () => {
    if(location.pathname == "/parentzone/usernames-zone") setMainStyle("parentZoneMain");
    else if(location.pathname == "/parentzone/uploads") setMainStyle("parentZoneMain uploads");
    else if(location.pathname == "/parentzone/messages") setMainStyle("parentZoneMain uploads messages");
    else if(location.pathname == "/parentzone/rewards") setMainStyle("parentZoneMain uploads rewards");
    else if(location.pathname == "/parentzone/settings") setMainStyle("parentZoneMain uploads settings");
  }

  return (
    <div>
      <div className={mainStyle}>
        <Header activeUser={true}/>
        <main className="container-fluid">
            <HeadTitle />
            <ResponsiveMenu />
            <div className="d-flex screen container pz" style={{justifyContent: "start", alignItems:"start"}}>
              <Menu />
              { <Outlet /> }
            </div>
        </main>
        <Footer  activeUser={true} />
      </div>
    </div>
  );
}

export default ParentZone;
