// @ts-nocheck
import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';

import '../../../css/verificationComp.scss';
import '../../../css/successRegComp.scss';

import '../../../css/responsive/verificationComp.scss';
import '../../../css/responsive/successReg.scss';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';


const SuccessReg: React.FC = (props) => {

  useEffect(()=>{
    SetHeaderMetaTags({ title: 'Success Registration' });
    dataLayer.push({ 'event': 'registration-end' });
    window.scrollTo(0, 0)
},[])
    
  return (
    <div className="parentZoneMain">
        <Header activeUser={false}/>
        <main className="container-fluid">
            <div className="d-flex screen sr container" style={{justifyContent: "start", alignItems:"start"}}>
              <div className="successReg">
                    <p className="title">That’s it! Registration is completed.</p>
                    <p>Your child can now download the MyZone app and sign in using the activation code.</p>
                    <div className='links-store'>
                      <a className='google-play' href='https://play.google.com/store/apps/details?id=com.myZone.myZone&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img src='/images/main/google-play.png' alt='Get it on Google Play' />
                      </a>
                      <a className='app-store' href="https://apps.apple.com/us/app/my-zone/id1618005176?itsct=apps_box_badge&itscg=30200">
                        <img src="/images/main/app-store.svg" alt="Download on the App Store" />
                      </a>
                    </div>
                    <p>We invite you to visit the <Link to="/parentzone">ParentZone</Link> any time to track your child's progress and more.</p>
                    
               </div>
            </div>
        </main>
        <Footer activeUser={true} />
    </div>
  );
}

export default SuccessReg;
