import { createSlice, PayloadAction } from '@reduxjs/toolkit';


type ParenZoneState = {
    chosenPage: string;
    userAuth: boolean;
}

const initialState: ParenZoneState = {
    chosenPage: "usernames-zone",
    userAuth: false
}

const parentZoneSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setChosenPage(state, action: PayloadAction<string>) {
      state.chosenPage = action.payload;
    },
    setUserAuth(state, action: PayloadAction<boolean>) {
      state.userAuth = action.payload;
    }
  }
});

export const {
    setChosenPage,
    setUserAuth
} = parentZoneSlice.actions;

export default parentZoneSlice.reducer;