// @ts-nocheck
export interface IProps {
    title: string;
}

export const SetHeaderMetaTags = ({ title }: IProps) => {
    if (typeof title === 'undefined' && !title) {
        document.title = "MyZone";
        document.querySelector('meta[name="description"]').content = "MyZone";
    }
    else {
        document.title = title + " | MyZone";
        document.querySelector('meta[name="description"]').content = title;
    }
    if (window.dataLayer)
        dataLayer.push({ 'event': title });
}