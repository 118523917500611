import { createSlice, PayloadAction } from '@reduxjs/toolkit';


type LockState = {
  isLocked: boolean | undefined;
}

const initialState: LockState = {
    isLocked: undefined
}

const lockSlice = createSlice({
  name: 'lock',
  initialState,
  reducers: {
    setLock(state, action: PayloadAction<boolean>) {
      state.isLocked = action.payload;
    }
  }
});

export const {
    setLock
} = lockSlice.actions;

export default lockSlice.reducer;