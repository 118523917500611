import React, { useEffect, useLayoutEffect, useState, useRef, RefObject } from 'react';
import * as ReactDOM from 'react-dom';
import { useParams } from "react-router-dom";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

import { Link, useNavigate  } from 'react-router-dom';
import { useForm, Resolver, SubmitHandler } from "react-hook-form";
import Header from '../../header/headerComp';
import Footer from '../../footer/footerComp';

import '../../../css/sendPrizeComp.scss';
import '../../../css/responsive/sendPrizeComp.scss';
import '../../../css/popup.scss';
import ConfirmPopup from '../../../common/confirm-popup/confirmPopup';
import { FETCH_API, IApiResponseModel } from '../../../services/api';
import { GET_PRIZE, POST_SEND_PRIZE } from '../../../services/consts';
import { useJsApiLoader } from '@react-google-maps/api';
import { SetHeaderMetaTags } from '../../../common/SetHeaderMetaTags/SetHeaderMetaTags';

interface FormValues {
    firstName: string,
    lastName: string,
    address?: string,
    apartment: string,
    city?: string,
    country?: string,
    region?: string,
    zipcode: string,
    phone: string
  };


const SendPrize = (props: any) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY || "",
        libraries: ['places']
      })
    const { register, handleSubmit, getValues, setError, clearErrors, formState: { errors }  } = useForm<FormValues>();
    const [termsPopup, setTermsPopup] = useState<boolean>(false);
    
    const [country, setCountry] = useState<string>("United States");
    const [city, setCity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [ifPhonePopup, setIfPhonePopup] = useState<boolean>(false);
    const [ifConfirmPopup, setIfConfirmPopup] = useState<boolean>(false);
    const [popupAnswer, setPopupAnswer] = useState<boolean>(false);
    const [prizeData, setPrizeData] = useState<any>(null);
    const [ifSuccessSended, setIfSuccessSended] = useState<boolean>(false);
    const [countErrors, setCountErrors] =  useState<number>(0);
    const { id } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        SetHeaderMetaTags({ title: 'Send Prize' });
    }, []);

    useEffect(()=>{
        if(!id) navigate('/');
        else getPrizeData();
    },[])

    useEffect(()=>{
        if(id && popupAnswer == true) sendPrizeOrder();
    },[popupAnswer])

    const getPrizeData = async () => {
        let data:IApiResponseModel<any> = await FETCH_API<IApiResponseModel<any>>(GET_PRIZE+"/"+id, "GET");
        if(!data.data.prize) navigate('/');
        else setPrizeData(data.data.prize);
        setPrizeData(data.data.prize);
    }

    const validateCountry = (_country: string) => {
        if(!_country){
            setError("country", {type: "custom"});
        }
        else {
            clearErrors('country');
        }
    }

    const validateRegion = (_region: string) => {
        if(!_region){
            setError("region", {type: "custom"});
        }
        else {
            clearErrors('region');
        }
    }

    const validateAddress = (_address: string) => {
        if(!_address){
            setError("address", {type: "custom"});
        }
        else {
            clearErrors('address');
        }
    }

    const onFormSub = async (data: FormValues) => {
        setIfConfirmPopup(true);
        return false;
    }

    const sendPrizeOrder = async () => {
        let body;
        let apartmentNum = getValues("apartment");
        if(apartmentNum) body = {apartment: apartmentNum}
        body = {
            ...body,
            parentFirstName: getValues("firstName"),
            parentLastName: getValues("lastName"),
            adress: address,
            city: city,
            region: region,
            state: country,
            zip: getValues("zipcode"),
            phone: getValues("phone"),
          }
        let data:IApiResponseModel<any> = await FETCH_API<IApiResponseModel<any>>(POST_SEND_PRIZE + "/" + id, "POST", body);
        if(data.data) setIfSuccessSended(true);
    }

  return (
    <>
    <div className="sendPrize">
        {ifConfirmPopup && 
            <ConfirmPopup setOpen={setIfConfirmPopup} setAnswer={setPopupAnswer} confirmMode={ifSuccessSended ? false : true}/>
        }
        <Header activeUser={false}/>
        <main className="container-fluid">
            <div className='headTitle'>
                <h1 className='container'>Reward</h1> 
            </div>
            <div className="container form">
                <form className='d-flex' onSubmit={handleSubmit(onFormSub)} name='send-prize'>
                    {prizeData && 
                        <figure>
                            <img src={prizeData.image} alt='Prize' />
                            <figcaption>{prizeData.description}</figcaption>
                        </figure>
                    }
                    <div>
                        <h3>Shipping address</h3>
                        
                        <div className='d-flex inputs-row'>
                            <div>
                                <input {...register("firstName", { required: true, maxLength: 32 })} type='text' name='firstName' placeholder='First name*' maxLength={32}/>
                                <p className={errors.firstName ? 'opacity-1' : 'opacity-0'}>*First name required</p>
                            </div>
                            <div>
                                <input {...register("lastName", { required: true, maxLength: 32 })} type='text' name='lastName' placeholder='Last name*' maxLength={32}/>
                                <p className={errors.lastName ? 'opacity-1' : 'opacity-0'}>*Last name required</p>
                            </div>
                        </div>
                        
                        {isLoaded &&
                            <>
                                <Autocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                                    onPlaceSelected={(place: any) => {
                                        place.address_components.map((item: any)=>{
                                            if(item.types[0] == "administrative_area_level_1") setCity(item.long_name);
                                            if(item.types[0] == "administrative_area_level_2") setRegion(item.long_name);
                                        })
                                        validateAddress(place.formatted_address);
                                        setAddress(place.formatted_address);
                                    }}
                                    libraries={["places"]}
                                    options={{
                                        types: ["address"],
                                        componentRestrictions: { country: "us" },
                                    }}
                                    type='text' name='address' placeholder='Address*' minLength={5}
                                />
                                <p className={errors.address ? 'opacity-1' : 'opacity-0'}>*Address required</p>
                            </>
                        }
                        
                        <input {...register("apartment")} type='text' name='apartment' placeholder='Apartment, suite, etc. (optional)' />
                        <p className='opacity-0'>*Apartment required</p>
                        <div className='d-flex select-row'>
                            <div>
                                {errors.country && <p>*Country required</p>}
                                <select>
                                    <option>United States</option>
                                </select>
                            </div>
                            
                            <div>
                                <input {...register("zipcode", { required: true })} type='text' name='zipcode' placeholder='ZIP code*'/>
                                <p className={errors.zipcode ? 'opacity-1' : 'opacity-0'}>*ZIP code required</p>
                            </div>
                        </div>
                        {ifPhonePopup &&
                            <div className='phone-popup-wrapper'>
                                <div className="phone-popup">
                                    <img src='/images/signUp/closeIcon.png' alt='' onClick={()=>{setIfPhonePopup(false)}}/>
                                    <p className='opacity-1'>In case we need to contact you about your order</p>
                                </div>
                            </div>
                        }
                        <div className='d-flex phone-row'>
                            <input {...register("phone", { required: true, minLength: 8})} type='tel' name='phone' placeholder='Phone*' />
                            <button className='d-flex' onClick={(e)=>{e.preventDefault(); setIfPhonePopup(!ifPhonePopup);}}>
                                <img src='/images/signUp/qIcon.png' alt='' />
                            </button>
                        </div>
                        <p className={errors.phone?.type === 'required' ? 'opacity-1' : 'opacity-0'}>*Phone required</p>
                        <p className={errors.phone?.type === 'minLength' ? 'opacity-1' : 'opacity-0'}>*Invalid phone number</p>
                        <br />
                        <div className='d-flex submit-btn'>
                            <input className={!errors.firstName && !errors.lastName && !errors.address && !errors.city && !errors.country && !errors.region && !errors.zipcode && !errors.phone
                                            ? "active" : ""
                                            }
                                    type='submit'
                                    name='order'
                                    value='Order Reward'
                                        onClick={()=>{setIfPhonePopup(false); validateCountry(country); validateRegion(region); validateAddress(address);}} 
                            />
                        </div>
                    </div>
                </form>
            </div>
        </main>
        <Footer displayPopupFromSignUp={termsPopup} displayPopup={setTermsPopup} activeUser={true} />
    </div>
    </>
  );
}

export default SendPrize;
