// @ts-nocheck
import React, { useEffect, useState, useRef, SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Linkify from "linkify-react";

import '../../css/footerComp.scss';
import '../../css/responsive/footerComp.scss';
import { tsPropertySignature } from '@babel/types';
import { globalParams } from '../../reducer/globalParamsComp';
import { FETCH_API } from '../../services/api';
import { GET_PAGES } from '../../services/consts';
import { useAppDispatch, useAppSelector } from '../../reducer/hook';
import { setAboutOpen } from '../../reducer/aboutSlice';

interface isActiveUser {
    activeUser: boolean,
    footerSignUpBtn?: boolean
    textModal?: any[]
    showAboutUs?: boolean
    displayPopupFromSignUp?: boolean
    displayPopup?: any
}

const Footer: React.FC<isActiveUser> = (props) => {

    const [displayAboutUs, setDisplayAboutUs] = useState<boolean>(false);
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [displayModalText, setDisplayModalText] = useState<string>("");
    const [text, setText] = useState<any>(globalParams.footerText);
    const { isAboutOpen } = useAppSelector(state => state.about);
    const { utmUrlParams } = useAppSelector(state => state.path);
    const location = useLocation();

    const dispatch = useAppDispatch();

    const modalRef = useRef<any>();

    useEffect(() => {
        setDisplayAboutUs(isAboutOpen);
    }, [isAboutOpen])

    useEffect(() => {
        setText(globalParams.footerText);
    }, [globalParams.footerText])

    useEffect(() => {
        getFooterText();
    }, [])

    const getFooterText = async () => {

        let data;
        if (props.textModal != null) setText(props.textModal);
        else if (globalParams.footerText.length != 0) {
            setText(globalParams.footerText);
        }
        else {
            data = await FETCH_API(GET_PAGES, "GET");
            data = [data.data[27].body, data.data[30].body, data.data[31].body, data.data[29].body];
            setText(data);
        }
    }

    const setModal = (_num: number) => {
        setDisplayModalText("");
        if (localStorage["home"]) {
            let footerText = JSON.parse(localStorage["home"]);
            footerText?.data?.map((item: any) => {
                if (item.title == "Credits") setDisplayModalText(item.body);
            });
        }
        setDisplayModal(true);
    }

    const renderLink = ({ attributes, content }: any) => {
        const { href, ...props } = attributes;
        return (
            <>
                <span>
                    <a href={href} target="_blank">{content}</a>
                    <br />
                </span>
            </>
        )
    };

    const closeOnEsc = (event: any) => {
        if (event.code == "Escape") setDisplayModal(false);
    }

    return (
        <footer id="footer" className="container-fluid">
            {displayModal || props.displayPopupFromSignUp == true ?
                <div style={{ border: "none" }} tabIndex={1} className="footModal" onKeyDown={closeOnEsc} ref={modalRef}>
                    <button id="closePopup" onClick={() => {
                        setDisplayModal(false);
                        if (!props.displayPopup || props.displayPopup === undefined) return;
                        props.displayPopup(false)
                    }}></button>
                    <div>
                        <Linkify options={{ render: renderLink }}>
                            {displayModalText}
                        </Linkify>
                    </div>
                </div>
                : ""
            }

            <div className="container d-flex" style={props.activeUser && !props.showAboutUs ? { justifyContent: "flex-end" } : { justifyContent: "space-between" }}>
                {!props.activeUser ?
                    <><article style={!props.showAboutUs ? { display: "none" } : { display: "block" }}>
                        <h3>About Us</h3>

                        <p style={displayAboutUs ? { WebkitLineClamp: "unset" } : { WebkitLineClamp: "6" }}>
                            {text[0]}
                        </p>
                        <p className="displayRead" onClick={() => { dispatch(setAboutOpen(!displayAboutUs)); }}>{displayAboutUs ? "Read Less" : "Read More"} <span>&gt;&gt;</span></p>
                    </article>
                    </>
                    : ""
                }
                {props.footerSignUpBtn ?
                    <div className="SignUpBtn">
                        {
                            location.search.toLowerCase().includes('utm_source') ?
                                <Link to={`/signup/${utmUrlParams}`}>Sign Up for Free</Link>
                                : <Link to='/signup'>Sign Up for Free</Link>

                        }
                        <p>No credit card required</p>
                    </div>
                    : ""
                }
                <div className="footMenu">
                    <Link to="/" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                        <img src='/images/main/logo.png' alt='MyZone' />
                    </Link>
                    <p>&copy; <span>ToMyZone</span>, All Rights Reserved</p>
                    <div>
                        <p><span>Contact Us:</span> <a className="contactEmail" href="mailto:contact@tomyzone.com">contact@tomyzone.com</a></p>
                    </div>
                    <nav className="d-flex">
                        <div>
                            <Link to={"/legal/myzone-website-privacy-policy.pdf"} target="_blank" className="border">Privacy Policy</Link>
                        </div>
                        <div className="d-flex">
                            <Link to={"/legal/myzone-terms-of-use.pdf"} target="_blank">Terms of Use</Link>
                        </div>
                        <p onClick={() => { setModal(3) }}>Credits</p>
                    </nav>
                </div>
            </div>
        </footer>
    );
}

export default Footer;